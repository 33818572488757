import { Box } from '@chakra-ui/react';

const Dashboards = () => {
  return (
    <Box px={4} py={2} rounded={4}  >
      Dashboards
    </Box>
  )
}

export default Dashboards