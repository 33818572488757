import { Box, Flex, Grid, Text, VStack } from '@chakra-ui/react'
import Diagnosis from './Diagnosis'
import MoreInfo from './MoreInfo'
import Validate from './validerCPA/Validate'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getCPA, getPatient } from 'src/view-models/Patient'
import PatientInfo from './PatientInfo'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import Loading from 'src/components/loading/Loading'
import Accouchement from './Accouchement'
import Allergie from './Allergie'
import Bilan from './Bilan'
import ValiderModal from './validerCPA/ValiderModal'
import Treatments from './Treatments'


const ProfileComp = ({ setActiveTabIndex }: { setActiveTabIndex: any }) => {
    const { id } = useParams()

    const dispatch = useAppDispatch()
    const { patient } = useAppSelector((state) => state.patient)
    const { cpa, isValidate } = useAppSelector((state) => state.practitioner)
    const [isLoadingPatient, setIsLaodingPatient] = useState(false)
    const [isLoadingCPA, setIsLaodingCPA] = useState(false)

    useEffect(() => {
        const fetchPatient = async () => {
            setIsLaodingPatient(true)
            await getPatient(id!, 'medecin', dispatch)
            setIsLaodingPatient(false)
        }

        fetchPatient()
    }, [id])


    useEffect(() => {
        const fetchCPA = async () => {
            setIsLaodingCPA(true)
            await getCPA(id!, dispatch)
            setIsLaodingCPA(false)
        }
        fetchCPA()
    }, [id])
    if (isLoadingPatient || isLoadingCPA) {
        return <Loading />
    }

    if (!isLoadingPatient && !isLoadingCPA && !patient) {
        return <Box backgroundColor='white' boxShadow='md' rounded={10} w={{ base: '90%', sm: '80%', lg: '70%' }} mx='auto' my={6} p={4}>
            <Text fontWeight='bold' >Assurez-vous de cibler le bon patient!</Text>
            <Link to={`/medecin/patients`} className='underline'>Retour à la page des patients</Link>
        </Box>
    }

    return (
        <Box >
            {isValidate === 1 ? (
                <Box py={4} px={7} w='100%' backgroundColor='#ECECEC' >
                    <Text fontWeight='bold' >Vous avez valider ce dossier!</Text>
                </Box>
            ) : null}
            <ValiderModal />
            <Box position='relative' px={4} py={2} rounded={4}>
                <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={4} >
                    <Box flex={2}  >
                        <PatientInfo isLoading={isLoadingPatient} />
                    </Box>
                    <Box flex={1} >
                        <Accouchement status={cpa?.mode_acouchement ? cpa?.mode_acouchement?.client_answers[0]?.answer?.split(' ')?.slice(1)?.join(' ') : 'à renseigner'} color='red' title="Voie d’accouchement prévue" isUpdated={cpa?.cpa_conclusion_status} cpaFlag={cpa?.mode_acouchement ? cpa?.cpa?.mode_acouchement[0]?.answer?.split(' ')?.slice(1)?.join(' ') : 'à renseigner'} />
                    </Box>
                </Flex>
                <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3,1fr)' }} gap={2} my={4} >
                    <Diagnosis status={cpa?.cpa_conclusion_status === 0 ? cpa?.ASA?.score > 0 ? "> 1" : '=1' : cpa?.cpa?.asa_is_updated > 0 ? cpa?.cpa?.score_asa_count > 1 ? `>1 (${cpa?.cpa?.score_asa_count})` : '=1' : cpa?.ASA?.score > 0 ? "> 1" : '=1'} color={cpa?.cpa_conclusion_status === 0 ? cpa?.ASA?.score > 0 ? "red" : 'green' : cpa?.cpa?.asa_is_updated > 0 ? cpa?.cpa?.score_asa_count > 1 ? "red" : 'green' : cpa?.ASA?.score > 0 ? "red" : 'green'} title='Score ASA' resultats={cpa?.ASA?.question} cpaFlag={cpa?.cpa?.flag_asa} isUpdated={cpa?.cpa_conclusion_status} />
                    <Diagnosis status={cpa?.cpa_conclusion_status === 0 ? cpa?.grossesse_risque?.score > 0 ? "Oui" : 'Non' : cpa?.cpa?.grossess_risque_count === "Oui" || cpa?.cpa?.grossess_risque_count === "oui" ? "Oui" : "Non"} color={cpa?.cpa_conclusion_status === 0 ? cpa?.grossesse_risque?.score > 0 ? "red" : 'green' : cpa?.cpa?.grossess_risque_count === "Oui" || cpa?.cpa?.grossess_risque_count === "oui" ? "red" : 'green'} title='Grossesse à risque' resultats={cpa?.grossesse_risque?.question} cpaFlag={cpa?.cpa?.flag_grossess} isUpdated={cpa?.cpa_conclusion_status} />
                    <Diagnosis status={cpa?.cpa_conclusion_status === 0 ? cpa?.diathèse_hémorragique?.score > 0 ? "Oui" : 'Non' : cpa?.cpa?.risque_diahrse_count === "Oui" || cpa?.cpa?.risque_diahrse_count === "oui" ? "Oui" : "Non"} color={cpa?.cpa_conclusion_status === 0 ? cpa?.diathèse_hémorragique?.score > 0 ? "red" : 'green' : cpa?.cpa?.risque_diahrse_count === "Oui" || cpa?.cpa?.risque_diahrse_count === "oui" ? "red" : "green"} title="Signe(s) de diathèse hémorragique" resultats={cpa?.diathèse_hémorragique?.question} cpaFlag={cpa?.cpa?.flag_diathese} isUpdated={cpa?.cpa_conclusion_status} />
                    <Diagnosis status={cpa?.cpa_conclusion_status === 0 ? cpa?.autres?.score > 0 ? "Oui" : 'Non' : cpa?.cpa?.flag_autre?.length > 0 ? "Oui" : "Non"} color={cpa?.cpa_conclusion_status === 0 ? cpa?.autres?.score > 0 ? "#FFA85A" : 'green' : cpa?.cpa?.flag_autre?.length ? "#FFA85A" : "green"} title="Autre(s) alerte(s)" resultats={cpa?.autres?.question} cpaFlag={cpa?.cpa?.flag_autre} isUpdated={cpa?.cpa_conclusion_status} />
                    <Allergie title={"Allergie(s)"} text={cpa?.allergie ? cpa?.allergie : "pas d’allergie connue"} cpaFlag={cpa?.cpa?.allergies ? cpa?.cpa?.allergies : "pas d’allergie connue"} isUpdated={cpa?.cpa_conclusion_status} />
                    <MoreInfo title='Consultation du 7 ème mois' desc={cpa?.comment_safe_femme} />
                    <Box></Box>
                    <Bilan status={null} color='white' title="Bilan biologique recommandé" resultats={cpa?.billan_biologiques} isUpdated={cpa?.cpa_conclusion_status} cpaFlag={cpa?.cpa?.billans_biologique} />
                    <Treatments status={null} color='white' title="Traitments" resultats={cpa?.patient?.treatments} />
                </Grid>
                <VStack mt={4} alignItems={'flex-start'} w='100%'>
                    <MoreInfo title='Conclusion anesthésiste' desc={cpa?.comment_cpa} />
                </VStack>
            </Box>
            <Validate patientId={id!} setActiveTabIndex={setActiveTabIndex} />
        </Box>
    )
}

export default ProfileComp