import { Box, Flex,Text, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react'
import DataTable from 'src/components/tables/DataTable';
import { colDefs } from './constants';
import { getArchivedPatients, getPatients } from 'src/view-models/Patient';
import Loading from 'src/components/loading/Loading';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import DeleteModal from './components/DeleteModal';
import { RiLoader2Fill } from 'react-icons/ri';
import SerchPatients from 'src/components/filters/SearchPatients';

const Patients = () => {
  const dispatch = useAppDispatch()

  const {patients,isSendEmail,status} = useAppSelector((state)=>state.patient)
  const [isLoading,setIsLoading] = useState(true)
  useEffect(() => {
    const fetchPatients = async () => {
      if(status === 1) {
        setIsLoading(true)
        await getPatients('medecin',dispatch)
      }else{
        setIsLoading(true)
        await getArchivedPatients('medecin',dispatch)
      }
      setIsLoading(false)
    }
    fetchPatients()
  }, [status])
  
  if(isLoading){
    return <Loading />
  }


  return (
    <Box  px={4} py={2} rounded={4}  >
      
      <DeleteModal />
      <Flex height={'100px'} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >Patients</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez trouver la liste des patients actifs de votre établissement </Text>
        </Box>
      </Flex>
      <VStack  alignItems='start' my={4}  className='relative' >
          {isSendEmail && (
            <Flex justifyContent='center' alignItems='center' pos='absolute' top={0} left={0} className='z-50 h-[27rem] loading-bg w-full' >
              <RiLoader2Fill size={30} className='animate-spin' />
            </Flex>
          )}
          <SerchPatients />
          <DataTable columns={colDefs} data={patients} />
      </VStack>
    </Box>
)
}

export default Patients