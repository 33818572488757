import { Flex, Grid } from '@chakra-ui/react'
import BasicInfo from './BasicInfo'
import { useAppSelector } from 'src/redux/hooks'
import { RiLoader2Fill } from 'react-icons/ri'

import ageImage from '../../../../../assets/accouchement.png'
import poidImage from '../../../../../assets/poid.png'
import tailleImage from '../../../../../assets/taille.png'
import accouh2Image from '../../../../../assets/accouch2.png'

const PatientInfo = ({ isLoading }: { isLoading: boolean }) => {
  const { cpa } = useAppSelector((state) => state.practitioner)
  if (isLoading) {
    return (
      <Flex justifyContent='center' w='100%' p={4} backgroundColor='white' rounded={8} boxShadow='sm'>
        <RiLoader2Fill size={30} className='animate-spin' />
      </Flex>
    )
  }

  return (
    <Grid px={4} py={2} backgroundColor='white' rounded={8} boxShadow='sm' templateColumns={{ base: 'repeat(2,1fr)', sm: 'repeat(2,1fr)', md: 'repeat(3,1fr)', xl: 'repeat(3,1fr)', '2xl': 'repeat(4,1fr)' }} justifyContent='center' mx='auto' gap={4} >
      <BasicInfo info1={`${cpa?.patient?.full_name?.split(' ')[0]?.charAt(0).toUpperCase()}${cpa?.patient?.full_name?.split(' ')[0]?.slice(1)} ${cpa?.patient?.full_name?.split(' ')[1]?.charAt(0).toUpperCase()}${cpa?.patient?.full_name?.split(' ')[1]?.slice(1)}`} info2={`id : ${cpa?.patient?.id}`} />
      <BasicInfo info1="Téléphone" info2={cpa?.patient?.phone ? cpa?.patient?.phone : 'ne rien mettre comme texte'} />
      <BasicInfo info1="E-mail" info2={cpa?.patient?.email ? cpa?.patient?.email : 'ne rien mettre comme texte'} />
      <BasicInfo image={ageImage} info1="Âge" info2={cpa?.patient?.birthdate ? `${cpa?.patient?.birthdateOrg} (${cpa?.patient?.birthdate} ans)` : 'ne rien mettre comme texte'} />
      <BasicInfo image={poidImage} info1="Poids" info2={`${cpa?.patient?.poid_actuelle?.client_answers ? cpa?.patient?.poid_actuelle?.client_answers[0]?.answer : 'ne rien mettre comme texte'} ${cpa?.patient?.poid_actuelle?.client_answers && 'kg'} ${cpa?.patient?.current_imc && `(IMC ${cpa?.patient?.current_imc})`}`} />
      <BasicInfo image={tailleImage} info1="Taille" info2={`${cpa?.patient?.taille?.client_answers[0]?.answer ? cpa?.patient?.taille?.client_answers[0]?.answer : 'ne rien mettre comme texte'} ${cpa?.patient?.taille?.client_answers[0]?.answer && 'cm'}`} />
      <BasicInfo image={accouh2Image} info1="Date d’accouchement" info2={cpa?.patient?.date_acouchement?.client_answers[0]?.answer} />
      <BasicInfo info1="Gestité/Parité:" info2={`${cpa?.patient?.gestite?.client_answers[0]?.id === 4 ? `G ${cpa?.patient?.gestite?.client_answers[0]?.answer ? cpa?.patient?.gestite?.client_answers[0]?.answer : 'ne rien mettre comme texte'}` : `G ${cpa?.patient?.gestite?.client_answers[1]?.answer ? cpa?.patient?.gestite?.client_answers[1]?.answer : 'ne rien mettre comme texte'}`}/${cpa?.patient?.gestite?.client_answers[0]?.answer === 5 ? `P ${cpa?.patient?.gestite?.client_answers[0]?.answer ? cpa?.patient?.gestite?.client_answers[0]?.answer : 'ne rien mettre comme texte'}` : `P ${cpa?.patient?.gestite?.client_answers[1]?.answer ? cpa?.patient?.gestite?.client_answers[1]?.answer : 'ne rien mettre comme texte'}`}`} />
    </Grid>
  )
}

export default PatientInfo