import { Box, Flex, Grid, HStack, Stack, Text } from '@chakra-ui/react'

const PersoInfoCard = ({ patient, accouchement }: { patient: any, accouchement: any }) => {
    return (
        <Box my={4} className='flex flex-col gap-4' >
            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                <Stack direction={['column', 'row']}>
                    <HStack>
                        <Text fontSize={{ base: '14', sm: '16' }}>Nom : </Text>
                        <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{patient?.full_name ? `${patient?.full_name?.split(' ')[0].charAt(0).toUpperCase()}${patient?.full_name?.split(' ')[0]?.slice(1)}` : 'ne rien mettre comme texte'}</Text>
                    </HStack>
                    <HStack>
                        <Text fontSize={{ base: '14', sm: '16' }}>Prénom : </Text>
                        <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{patient?.full_name ? `${patient?.full_name?.split(' ')[1].charAt(0).toUpperCase()}${patient?.full_name?.split(' ')[1]?.slice(1)}` : 'ne rien mettre comme texte'}</Text>
                    </HStack>
                </Stack>
                <HStack>
                    <Text fontSize={{ base: '14', sm: '16' }}>Date de naissance : </Text>
                    <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{`${patient?.birthdateOrg} (${patient?.birthdate} ans)` || "ne rien mettre comme texte"}</Text>
                </HStack>
            </Flex>

            <Flex alignItems='center' justifyContent='space-between' gap={6} flexWrap='wrap' >
                <Stack direction={['column', 'row']}>
                    <HStack>
                        <Text fontSize={{ base: '14', sm: '16' }}>Gestité/Parité : </Text>
                        <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{`G ${patient?.gestite?.client_answers[0]?.id === 4 ? patient?.gestite?.client_answers[0]?.answer ? patient?.gestite?.client_answers[0]?.answer : 'ne rien mettre comme texte' : patient?.gestite?.client_answers[1]?.answer ? patient?.gestite?.client_answers[1]?.answer : 'ne rien mettre comme texte'}/P ${patient?.gestite?.client_answers[0]?.answer === 5 ? patient?.gestite?.client_answers[0]?.answer ? patient?.gestite?.client_answers[0]?.answer : 'ne rien mettre comme texte' : patient?.gestite?.client_answers[1]?.answer ? patient?.gestite?.client_answers[1]?.answer : 'ne rien mettre comme texte'}`}</Text>
                    </HStack>
                </Stack>
                <HStack>
                    <Text fontSize={{ base: '14', sm: '16' }}>Date d’accouchement prévu : </Text>
                    <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{patient?.date_acouchement?.client_answers[0]?.answer || 'ne rien mettre comme texte'}</Text>
                </HStack>
            </Flex>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={2}>
                <HStack>
                    <Text fontSize={{ base: '14', sm: '16' }}>Taille : </Text>
                    <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{patient?.taille?.client_answers[0]?.answer || 'ne rien mettre comme texte'} cm</Text>
                </HStack>
                <HStack sx={{ display: 'flex', justifyContent: {base:'start', md:'center'} }} >
                    <HStack>
                        <Text fontSize={{ base: '14', sm: '16' }}>Poids avant la grossesse : </Text>
                        <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{`${patient?.poid_avant_grosesse?.client_answers ? patient?.poid_avant_grosesse?.client_answers[0]?.answer : 'ne rien mettre comme texte'} ${patient?.poid_avant_grosesse?.client_answers && 'kg'} ${patient?.pre_pregnancy_imc && `(IMC ${patient?.pre_pregnancy_imc})`} `}</Text>
                    </HStack>
                </HStack>
                <HStack sx={{display:'flex', justifyContent:{base:'start',md:'flex-end'}}}>
                    <HStack>
                        <Text fontSize={{ base: '14', sm: '16' }}>Poids actuel : </Text>
                        <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{`${patient?.poid_actuelle?.client_answers ? patient?.poid_actuelle?.client_answers[0]?.answer : 'ne rien mettre comme texte'} ${patient?.poid_actuelle?.client_answers && 'kg'} ${patient?.current_imc && `(IMC ${patient?.current_imc})`} `}</Text>
                    </HStack>
                </HStack>
            </Grid>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3,1fr)' }} gap={2} my={4} >
                <HStack>
                    <Text fontSize={{ base: '14', sm: '16' }}>Voie d'accouchement prévue : </Text>
                    <Text fontSize={{ base: '14', sm: '16' }} fontWeight='bold' >{accouchement?.client_answers[0]?.answer || 'ne rien mettre comme texte'}</Text>
                </HStack>
            </Grid>
        </Box>
    )
}

export default PersoInfoCard