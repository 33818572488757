import ActionTable from "./components/ActionTable";
import Status from "./components/Status";

export const colDefs = [
  {
    field: 'Actions',
    cellRenderer: ActionTable,
    cellRendererParams: (params: any) => {
      const rowData = params.data;
      return {
        id: `${rowData.id}`,
        name: `${rowData.name}`
      };
    },
    filter: false,
  },
  {
    field: 'Status',
    cellRenderer: Status,
    filter: true,
  },
  {
    headerName: "Name",
    field: "name",
  },
  {
    headerName: "Prénom",
    field: "family",
  },
  {
    headerName: "Identifiant",
    field: "identifier"
  },
  {
    headerName: "identifiant National",
    field: "identifiant_national"
  },
  {
    headerName: "Gender",
    field: "gender"
  },
  {
    headerName: "E-mail",
    field: "email",
    cellRenderer: (params: any) => {
      return params.data.email ? params.data.email : "null"
    }
  },
  {
    headerName: "Téléphone",
    field: "phone"
  },
  {
    headerName: "Pays",
    field: "country"
  },
  {
    headerName: "Address",
    field: "city"
  },
];