import {createSlice} from '@reduxjs/toolkit'


type StateType = {
    isOpen:boolean
    isOpenDelete : boolean
    questionId:null | string
    question:any 
    questions:any[]
    medecinCategoriesQuestions : [],
    medecinCategory:string |null,
    patientQuestions:any[]
    sageFemmeQuestions:any[]
    commentSagefemme:string | undefined,
    isPatientWatchedVideo:number | undefined,
    medecinQuestions : any
    isPatientValidating:boolean
    validatingPatientData:any
    isSeigefemmeValidating:boolean
    validatingSeigefemmeData:any,
    patientStep:number
    currentCategorie:any,
    isQuestionsMedecin:boolean,
    conclusionCPA:any,
    isValidateCPA:number,
    treatments:string[],

}

const initialState : StateType = {
    isOpen:false,
    isOpenDelete:false,
    questionId:null,
    questions:[],
    patientQuestions:[],
    sageFemmeQuestions:[],
    medecinQuestions:[],
    medecinCategoriesQuestions:[],
    question:null,
    isPatientValidating:false,
    validatingPatientData:null,
    isSeigefemmeValidating:false,
    isPatientWatchedVideo:0,
    validatingSeigefemmeData:null,
    patientStep:0,
    currentCategorie:"Généralités",
    isQuestionsMedecin:false,
    medecinCategory:null,
    conclusionCPA:null,
    commentSagefemme:undefined,
    isValidateCPA:0,
    treatments:[],

}


const questionSlice = createSlice({
    name:"question",
    initialState,
    reducers:{
        openModal : (state,action) => {
            state.isOpen = true
            state.questionId = action.payload ? action.payload : null
        },
        closeModal : (state) => {
            state.isOpen = false
            state.questionId = null
        },
        setQuestions : (state,action) => {
            state.questions = action.payload
        },
        setPatientQuestions : (state,action) => {
            state.patientQuestions = action.payload?.data
            state.isPatientWatchedVideo = action.payload?.start_question
        },
        setSageFemmeQuestions : (state,action) => {
            state.sageFemmeQuestions = action.payload?.data
            state.commentSagefemme = action.payload?.comment
            state.treatments = action?.payload?.treatments
        },
        setMedecinQuestions : (state,action) => {
            state.medecinQuestions = action.payload
            state.isValidateCPA = action.payload.validate_file
        },
        setQuestion : (state,action) => {
            state.question = action.payload
        },
        openDeleteModal : (state,action) => {
            state.isOpenDelete = true
            state.questionId = action.payload
        },
        closeDeleteModal : (state) => {
            state.isOpenDelete = false
            state.questionId = null
        },
        setPatientValidating:(state,action)=>{
            state.isPatientValidating = true
            state.validatingPatientData = action.payload
        },
        unsetPatientValidating:(state)=>{
            state.isPatientValidating = false
            state.validatingPatientData = null
        },
        setSeigefemmeValidating:(state,action)=>{
            state.isSeigefemmeValidating = true
            state.validatingSeigefemmeData = action.payload
        },
        unsetSeigefemmeValidating:(state)=>{
            state.isSeigefemmeValidating = false
            state.validatingSeigefemmeData = null
        },
        setPatientStep : (state,action) => {
            state.patientStep = action.payload.step
            state.currentCategorie = action.payload.category
        },
        unSetPateintStep : (state,action) => {
            state.patientStep = action.payload.step
            state.currentCategorie = action.payload.category
        },
        openMedecinQuestions : (state,action) => {
            state.isQuestionsMedecin=true
            state.medecinCategoriesQuestions = action.payload.questions
            state.medecinCategory = action.payload.category
        },
        closeMedecinQuestions : (state) => {
            state.isQuestionsMedecin=false
            state.medecinCategoriesQuestions = []
            state.medecinCategory = null
        },
        setConclusionCPA : (state,action) => {
            state.conclusionCPA = action.payload
        }
    }

})

export const {openModal,closeModal,openDeleteModal,closeDeleteModal,setQuestions, setPatientQuestions, setSageFemmeQuestions, setMedecinQuestions, setQuestion, setPatientValidating,unsetPatientValidating,setSeigefemmeValidating,unsetSeigefemmeValidating,setPatientStep,unSetPateintStep, openMedecinQuestions, closeMedecinQuestions,setConclusionCPA} = questionSlice.actions

export default questionSlice.reducer

