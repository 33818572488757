import {Button,FormControl, FormErrorMessage,FormLabel,HStack,Input,Stack,useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller,useFieldArray, useForm } from "react-hook-form"
import * as z from "zod"
import { useAppDispatch} from "src/redux/hooks"
import { useEffect, useState } from "react"
import Select from 'react-select';
import {updateQuestion } from "src/view-models/Question"

const formSchema = z.object({
  question : z.string(),
  type : z.string(),
  category : z.string().optional(),
  operator_flag_reponse : z.string().optional(),
  condition_flag_reponse : z.any().optional(),
  flag_reponse : z.string().optional(),
  operator_bilan_bilogique : z.string().optional(),
  condition_bilan_bilogique : z.any().optional(),
  bilan_bilogique : z.string().optional(),
  question_to : z.string().optional(),
  isFlag : z.boolean().default(false).optional(),
  isBilan : z.boolean().default(false).optional(),
  possibles_answers: z.array(z.string()).optional(),
  type_flag:z.any().optional(),
  })
  
  type EditQuestionInputs = z.infer<typeof formSchema>

  type Props = {
    setIsEdit:any,
    data:any
  }

const EditQuestion = ({setIsEdit,data}:Props) => {
  const dispatch = useAppDispatch() 
  const toast = useToast()
  const id = data?.question_id
  const { register,control, getValues, watch, setValue,handleSubmit,formState: { errors, isSubmitting },} = useForm<EditQuestionInputs>({
    resolver: zodResolver(formSchema),
    defaultValues:{
      question : data?.question,
      category:data?.category || undefined,
      type : data?.type,
      operator_flag_reponse : data?.operator_flag_reponse || undefined,
      condition_flag_reponse : data?.condition_flag_reponse || undefined,
      flag_reponse : data?.flag_reponse || undefined,
      operator_bilan_bilogique : data?.operator_bilan_bilogique || undefined,
      condition_bilan_bilogique : data?.condition_bilan_bilogique || undefined,
      bilan_bilogique : data?.bilan_bilogique || undefined,
      question_to : data?.question_to || undefined,
      type_flag : data?.type_flag?.map((type:any)=>type.type) || undefined,
      possibles_answers:data?.possibles_answers?.map((a:any)=>a.answer) || undefined,
      isFlag:data?.flag_reponse ? true:false,
      isBilan:data?.bilan_bilogique ? true:false
  }
  });
  
  const [answerOptions,setAnswerOptions] = useState<any>([])
  const {
    fields,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    //@ts-ignore
    name: "possibles_answers",
    
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    let thevalues ={
        ...values, 
        operator_bilan_bilogique:values.isBilan ? values.operator_bilan_bilogique : null,
        condition_bilan_bilogique:values.isBilan ? values.condition_bilan_bilogique : null,
        bilan_bilogique:values.isBilan ? values.bilan_bilogique : null,
        operator_flag_reponse:values.isFlag ? values.operator_flag_reponse : null,
        condition_flag_reponse:values.isFlag ? values.condition_flag_reponse : null,
        flag_reponse:values.isFlag ? values.flag_reponse : null,
        isFlag:undefined,
        isBilan:undefined,
        type_flag: values.isFlag ? values.type_flag ==="" ? null : JSON.stringify(values.type_flag) : null
      }

      if(data?.question_to === 'patient' && !values.category){
        toast({
          description: `vous devez sélectionner une catégorie`,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position:'top'
        })
        return null
      }
    const response = await updateQuestion(id,thevalues,toast,dispatch)
    if(response.status === true){
      setIsEdit(false)
    }
   
  }
      const typeOptions = [
        { value: 'checkbox', label: 'checkbox' },
        { value: 'selectbox', label: 'selectbox' },
        { value: 'number', label: 'number' },
        { value: 'text', label: 'text' },
      ];

      const flagOptions = [
        { value: '>', label: '>' },
        { value: '<', label: '<' },
        { value: '=', label: '=' },
      ];

      const isFlagOptions = [
        { value: false, label: 'Non' },
        { value: true, label: 'Oui' },
      ];

      const categoryOptions = [
        { value: 'Généralités', label: 'Généralités' },
        { value: 'Concernant votre grossesse actuelle', label: 'Concernant votre grossesse actuelle' },
        { value: 'Concernant votre santé en dehors de la grossesse', label: 'Concernant votre santé en dehors de la grossesse' },
      ];


      const QstoToOptions = [
        { value: 'patient', label: 'patient' },
        { value: 'sage-femme', label: 'sage-femme' },
      ];

      const FlagTypeOptions = [
        { value: 'ASA', label: 'ASA' },
        { value: 'Autre', label: 'Autre' },
        { value: 'Grossesse à risque', label: 'Grossesse à risque' },
        { value: 'diathèse hémorragique', label: 'diathèse hémorragique' },
      ];
      useEffect(()=>{
        const options = watch('possibles_answers')?.map((option)=>({
          value:option,
          label:option
        }))
        setAnswerOptions(options)
      },[getValues('possibles_answers')])

      const handleChangeType = (e : any) => {
        setValue('type',e.value)
      }

      const handleChangeFlag = (e : any) => {
        setValue('operator_flag_reponse',e.value)
      }

      const handleChangeFlagType = (options : any) => {
        const values = options?.map((o:any)=>(o.value.toString()))
        setValue('type_flag',values)
      }

      const handleChangeCategory = (e : any) => {
        setValue('category',e.value)
      }

      const handleChangeBilan = (e : any) => {
        setValue('operator_bilan_bilogique',e.value)
      }

      const handleChangeIsFlag = (e : any) => {
        setValue('isFlag',e.value)
      }

      const handleChangeIsBilan = (e : any) => {
        setValue('isBilan',e.value)
      }

      const handleChangeQstTo = (e : any) => {
        setValue('question_to',e.value)
      }

      const handleChangeFlagReponse = (options : any) => {
        setValue('condition_flag_reponse',options.value)
      }

      const handleChangeBilanReponse = (options : any) => {
        setValue('condition_bilan_bilogique',options.value)
      }

  return (
    <form  className="space-y-4 h-full overflow-y-auto px-6 py-4" onSubmit={handleSubmit(onSubmit)}>  
            <FormControl isRequired isInvalid={!!errors.question}>
              <FormLabel>Question</FormLabel>
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='question'
                placeholder='question'
                {...register("question")}
              />
              <FormErrorMessage>
                {errors.question && errors.question.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl {...register("type")}>
                <FormLabel>Type de reponse</FormLabel>
                  <Select
                    placeholder='Sélectionner' 
                    defaultValue={[{value:data?.type === 'date' ? 'text' : data?.type,label:data?.type === 'date' ? 'text':data?.type}]}
                    onChange={handleChangeType}
                    options={typeOptions}  
                  />
                <FormErrorMessage>
                    {errors.type && errors.type.message}
                </FormErrorMessage>
            </FormControl>
            {fields.map((item, index) => {
          return (
            <Stack direction={['column','row']}  key={item.id} alignItems='start'  >
              <Controller
              control={control}
              name={`possibles_answers.${index}`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder='Answer'
                />
               )}
              />
              <Button colorScheme="red"  type="button" onClick={() => remove(index)}>
                Delete
              </Button>
            </Stack>
          );
          })}
            {(watch('type') === 'selectbox' || watch('type') === 'checkbox')  ? (
              <Button
              type="button"
              onClick={() => {
                append('value');
                update(fields.length,'')
              }}
            >
              Ajouter response
            </Button>
            ):null}
            <FormControl {...register("question_to")} >
                <FormLabel>qui est considéré pour cette question ?</FormLabel>
                  <Select
                    placeholder='Sélectionner' 
                    defaultValue={[{value:data?.question_to,label:data?.question_to}]}
                    onChange={handleChangeQstTo}
                    options={QstoToOptions}  
                  />
                <FormErrorMessage>
                    {errors.question_to && errors.question_to.message}
                </FormErrorMessage>
            </FormControl>
            {data?.question_to === 'patient' ? (
              <FormControl {...register("category")}>
                <FormLabel>Categorie?</FormLabel>
                <Select
                    placeholder='Sélectionner' 
                  defaultValue={[{value:data?.category,label:data?.category}]}
                  onChange={handleChangeCategory}
                  options={categoryOptions}  
                />
              </FormControl>
            ) : null}
            <FormControl {...register("isFlag")}>
                <FormLabel>y a-t-il une réponse de flag</FormLabel>
                  <Select
                    placeholder='Sélectionner' 
                    defaultValue={[{value:data?.flag_reponse ? true:false,label:data?.flag_reponse ? 'true':'false'}]}
                    onChange={handleChangeIsFlag}
                    options={isFlagOptions}  
                  />
            </FormControl>
            {watch('isFlag') === true ? (
                <>
                <FormControl {...register("type_flag")} isRequired isInvalid={!!errors.type_flag}>
                  <FormLabel>Type de flag reponse?</FormLabel>
                    <Select
                    placeholder='Sélectionner' 
                      defaultValue={data?.type_flag?.map((t:any)=>({value:t.type,label:t.type}))}
                      isMulti
                      onChange={handleChangeFlagType}
                      options={FlagTypeOptions}  
                    />
                </FormControl>
                  <FormControl {...register("operator_flag_reponse")} >
                  <FormLabel>operator flag reponse</FormLabel>
                    <Select
                    placeholder='Sélectionner' 
                      defaultValue={[{value:data?.operator_flag_reponse,label:data?.operator_flag_reponse}]}
                      onChange={handleChangeFlag}
                      options={flagOptions}  
                    />
                  </FormControl>
                        <FormControl isRequired >
                        <FormLabel>Condition flag reponse</FormLabel>
                        {watch('type') !== 'checkbox' && watch('type') !== 'selectbox' ? (
                        <Input
                          boxShadow='sm'
                          disabled={isSubmitting}
                          rounded='5px'
                          type='text'
                          size='md'
                          id='condition_flag_reponse'
                          placeholder='condition_flag_reponse'
                          {...register("condition_flag_reponse")}
                        />
                        ):(
                          <Select
                    placeholder='Sélectionner' 
                            defaultValue={[{value:data?.condition_flag_reponse,label:data?.condition_flag_reponse}]}
                            onChange={handleChangeFlagReponse}
                            options={answerOptions}  
                          />
                        )}
                    </FormControl>    
                    <FormControl>
                      <FormLabel>flag reponse</FormLabel>
                      <Input
                        boxShadow='sm'
                        disabled={isSubmitting}
                        rounded='5px'
                        type='text'
                        size='md'
                        id='flag_reponse'
                        placeholder='flag_reponse'
                        {...register("flag_reponse")}
                      />
                      <FormErrorMessage>
                        {errors.flag_reponse && errors.flag_reponse.message}
                      </FormErrorMessage>
                    </FormControl>             
                </>
                
            ) : null}
            {/* okoko */}
            <FormControl {...register("isBilan")} >
                <FormLabel>y a-t-il une réponse de bilan bilogique</FormLabel>
                  <Select
                    placeholder='Sélectionner' 
                    defaultValue={[{value:data?.bilan_bilogique ? true:false,label:data?.bilan_bilogique ? 'true':'false'}]}
                    onChange={handleChangeIsBilan}
                    options={isFlagOptions}  
                  />
            </FormControl>
            {watch('isBilan') === true ? (
                <>
                  <FormControl {...register("operator_bilan_bilogique")}>
                  <FormLabel>operator bilan bbilogique</FormLabel>
                    <Select
                    placeholder='Sélectionner' 
                      defaultValue={[{value:data?.operator_bilan_bilogique,label:data?.operator_bilan_bilogique}]}
                      onChange={handleChangeBilan}
                      options={flagOptions}  
                    />
                  </FormControl>
                        <FormControl  >
                        <FormLabel>Condition flag reponse</FormLabel>
                        {watch('type') !== 'checkbox' && watch('type') !== 'selectbox' ? (
                        <Input
                          boxShadow='sm'
                          disabled={isSubmitting}
                          rounded='5px'
                          type='text'
                          size='md'
                          id='condition_bilan_bilogique'
                          placeholder='condition_bilan_bilogique'
                          {...register("condition_bilan_bilogique")}
                        />
                        ):(
                          <Select
                    placeholder='Sélectionner' 
                            defaultValue={[{value:data?.condition_bilan_bilogique,label:data?.condition_bilan_bilogique}]}
                            onChange={handleChangeBilanReponse}
                            options={answerOptions}  
                          />
                        )}
                    </FormControl>    
                    <FormControl >
                      <FormLabel>bilan bilogique</FormLabel>
                      <Input
                        boxShadow='sm'
                        disabled={isSubmitting}
                        rounded='5px'
                        type='text'
                        size='md'
                        id='bilan_bilogique'
                        placeholder='bilan_bilogique'
                        {...register("bilan_bilogique")}
                      />
                    </FormControl>             
                </>
                
            ) : null}
            
          <HStack mt={10}>
          <Button
            type='submit'
            onClick={handleSubmit(onSubmit)}
            isLoading={isSubmitting}
            p='4'
            rounded='20px'
            mx='auto'            
            w='40%'
            colorScheme='blue'
            variant='solid'
            
          >
            Modifier
            </Button>
        </HStack>                    
          </form>
  )
}

export default EditQuestion