import { Box, Text } from '@chakra-ui/react'
import CommentaireForm from './CommentaireForm'

const Commentaire = () => {
  return (
    <Box  p={4} rounded={8} boxShadow='md' backgroundColor='white' h='fit-content'>
        <Text fontSize={25} fontWeight='bold' >Communiquez une information importante à l’anesthésiste</Text>
        <CommentaireForm />
    </Box>
  )
}

export default Commentaire