import { Box, Flex } from "@chakra-ui/layout"
import ProfileNav from "./ProfileNav"
import NavMobile from "./NavMobile";

const Navbar = () => {
  return (
    <Box px={4} py={2} className="h-[10vh] z-50" boxShadow='sm' position='sticky' top={0} zIndex={100} backgroundColor='white'  >
      <Flex py={2} alignItems='center' justifyContent={{ base: 'space-between', lg: 'flex-end' }} gap={4} >
        <NavMobile />
        <Box>
          <ProfileNav />
        </Box>
      </Flex>
    </Box>
  )
}

export default Navbar