import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useAppSelector } from 'src/redux/hooks'
import MedecinQuestions from './MedecinQuestions'

const MedecinTabs = () => {
  const { medecinQuestions } = useAppSelector((state) => state.question)

  const QstNotReponseAriennes = medecinQuestions?.medecin_question?.filter((q: any) => q.category === "Gestion des voies aériennes")?.filter((q: any) => (q.client_answers?.length === 0 || q.client_answers[0]?.answer === null))
  const QstNotReponseScore = medecinQuestions?.medecin_question?.filter((q: any) => q.category === "Score")?.filter((q: any) => (q.client_answers?.length === 0 || q.client_answers[0]?.answer === null))
  const QstNotReponseInfos = medecinQuestions?.medecin_question?.filter((q: any) => q.category === "Informations et consentement")?.filter((q: any) => (q.client_answers?.length === 0 || q.client_answers[0]?.answer === null))
  const QstNotTraitement = medecinQuestions?.medecin_question?.filter((q: any) => q.category === "Traitement en cours")?.filter((q: any) => (q.client_answers?.length === 0 || q.client_answers[0]?.answer === null))

  return (
    <Tabs my={6} variant='unstyled'>

      <TabList  >
        <Flex alignItems='center' gap={4} w='100%' overflowX='auto' >
          <Tab color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8} _selected={{ color: 'white', bg: '#235EE8' }} position='relative'>
            {QstNotReponseAriennes?.length > 0 ? (
              <Flex w={4} h={4} rounded='full' bg='#235EE8' color='white' position='absolute' top={0} right={0} justifyContent='center' alignItems='center' >{QstNotReponseAriennes?.length}</Flex>
            ) : null}
            <Text>Gestion des voies aériennes</Text>
          </Tab>
          <Tab color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8} _selected={{ color: 'white', bg: '#235EE8' }} position='relative' >
            {QstNotReponseScore?.length > 0 ? (
              <Flex w={4} h={4} rounded='full' bg='#235EE8' color='white' position='absolute' top={0} right={0} justifyContent='center' alignItems='center' >{QstNotReponseScore?.length}</Flex>
            ) : null}
            <Text>Score</Text>
          </Tab>
          <Tab color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8} _selected={{ color: 'white', bg: '#235EE8' }} position='relative'>
            {QstNotReponseInfos?.length > 0 ? (
              <Flex w={4} h={4} rounded='full' bg='#235EE8' color='white' position='absolute' top={0} right={0} justifyContent='center' alignItems='center' >{QstNotReponseInfos?.length}</Flex>
            ) : null}
            <Text>Informations et consentement</Text>
          </Tab>
          <Tab color='black' bg='white' px={4} py={2} boxShadow='sm' rounded={8} _selected={{ color: 'white', bg: '#235EE8' }} position='relative'>
            {QstNotTraitement?.length > 0 ? (
              <Flex w={4} h={4} rounded='full' bg='#235EE8' color='white' position='absolute' top={0} right={0} justifyContent='center' alignItems='center' >{QstNotTraitement?.length}</Flex>
            ) : null}
            <Text>Traitement en cours</Text>
          </Tab>
        </Flex>
      </TabList>
      <TabPanels>
        <TabPanel>
          <MedecinQuestions tab={medecinQuestions?.medecin_question?.filter((q: any) => q.category === 'Gestion des voies aériennes')} category='Gestion des voies aériennes' />
        </TabPanel>
        <TabPanel>
          <MedecinQuestions tab={medecinQuestions?.medecin_question?.filter((q: any) => q.category === 'Score')} category='Score' />
        </TabPanel>
        <TabPanel>
          <MedecinQuestions tab={medecinQuestions?.medecin_question?.filter((q: any) => q.category === 'Informations et consentement')} category='Informations et consentement' />
        </TabPanel>
        <TabPanel>
          <MedecinQuestions tab={medecinQuestions?.medecin_question?.filter((q: any) => q.category === 'Traitement en cours')} category='Traitement en cours' />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default MedecinTabs