import { Box, Text } from '@chakra-ui/react'
import PatienteTraitementForm from './PatienteTraitementForm'

const PatienteTraitement = () => {
  return (
    <Box mt={4} p={4} rounded={8} boxShadow='md' backgroundColor='white' h='fit-content'>
      <Text fontSize={25} fontWeight='bold' >Merci de renseigner le traitement de la patiente</Text>
      <PatienteTraitementForm />
    </Box>
  )
}

export default PatienteTraitement