import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import PersoInfo from './components/infos/PersoInfo'
import QuestionsTabs from './components/questions/QuestionsTabs'
import Loading from 'src/components/loading/Loading'
import { getPatient } from 'src/view-models/Patient'
import Consclusion from './components/conclusion/Consclusion'
import ValiderCPA from './validerCPA/ValiderCPA'
import { getMedecinQuestions } from 'src/view-models/Question'
import MedecinTabs from './components/mdQuestions/MedecinTabs'
import ValiderModal from './validerCPA/ValiderModal'

const Consulter = () => {
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const { patient } = useAppSelector((state) => state.patient)
  const { medecinQuestions, isValidateCPA } = useAppSelector((state) => state.question)
  const [isLoadingPatient, setIsLaodingPatient] = useState(false)
  const [isLoadingCPA, setIsLaodingCPA] = useState(false)

  useEffect(() => {
    const fetchPatient = async () => {
      setIsLaodingPatient(true)
      await getPatient(id!, 'medecin', dispatch)
      setIsLaodingPatient(false)
    }
    fetchPatient()
  }, [id])

  useEffect(() => {
    const fetchCPA = async () => {
      setIsLaodingCPA(true)
      await getMedecinQuestions(id!, dispatch)
      setIsLaodingCPA(false)
    }
    fetchCPA()
  }, [id])

  const handleGetPDF = async () => {
    window.open(`${process.env.REACT_APP_HOST}/api/medecin/patient/get-pdf-cpa/${id}`, '_blank')
  }

  if (isLoadingPatient || isLoadingCPA) {
    return <Loading />
  }

  if (!isLoadingPatient && !isLoadingCPA && !patient) {
    return <Box backgroundColor='white' boxShadow='md' rounded={10} w={{ base: '90%', sm: '80%', lg: '70%' }} mx='auto' my={6} p={4}>
      <Text fontWeight='bold' >Assurez-vous de cibler le bon patient!</Text>
      <Link to={`/medecin/patients`} className='underline'>Retour à la page des patients</Link>
    </Box>
  }
  if (!isLoadingCPA && medecinQuestions?.length === 0) {
    return <Box backgroundColor='white' boxShadow='md' rounded={10} w={{ base: '90%', sm: '80%', lg: '70%' }} mx='auto' my={6} p={4}>
      <Text fontWeight='bold' >Quelquechose mal passe!</Text>
      <Link to={`/medecin/patients`} className='underline'>Retour à la page des patients</Link>
    </Box>
  }

  return (
    <Box >
      {isValidateCPA === 1 ? (
        <Box py={4} px={7} w='100%' backgroundColor='#ECECEC' >
          <Text fontWeight='bold' >Vous avez valider ce dossier!</Text>
        </Box>
      ) : null}
      <ValiderModal />
      <Box position='relative' px={4} py={2} rounded={4}>
        <Flex alignItems='center' justifyContent='space-between' >
          <Text fontWeight='bold' fontSize={20} mb={4} >Fiche CPA</Text>
          <Button mb={4} colorScheme='blue' onClick={handleGetPDF} >Exporter la fiche de synthèse au format PDF</Button>
        </Flex>
        <PersoInfo patient={medecinQuestions?.patient} accouchement={medecinQuestions?.mode_acouchement} />
        <QuestionsTabs />
        <MedecinTabs />
        <Consclusion />
        <ValiderCPA />
      </Box>
    </Box>
  )
}

export default Consulter