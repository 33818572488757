import { Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal, useToast } from '@chakra-ui/react'
import { CiMenuKebab } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { sendMail } from 'src/view-models/Patient';

type Props = {
  id: string
}

const Consultation = ({ id }: Props) => {
  const { status } = useAppSelector((state) => state.patient)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const toast = useToast()
  const sendMailToPatient = async () => {
    await sendMail(id, dispatch, toast)
  }

  const goToConsulter = () => {
    navigate(`/sage-femme/patients/${id}/consulter`)
  }

  if (status === 0) {
    return null
  }

  return (
    <Flex w='100%' justifyContent='center' alignItems='center' h='100%' >
      <Menu>
        <MenuButton as={IconButton}
          aria-label='Options'
          icon={<CiMenuKebab className='text-black text-3xl' />}
          variant='ghost'
        />
        <Portal  >
          <MenuList>
            <MenuItem>
              <Button w='100%' colorScheme='blue' onClick={goToConsulter} >Consulter CPA</Button>
            </MenuItem>
            <MenuItem >
              <Button w='100%' colorScheme='green' onClick={sendMailToPatient} >Envoyer mail</Button>
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    </Flex>

  )
}

export default Consultation