import { Button, FormControl, FormErrorMessage, FormLabel, HStack, Input, Stack, useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import * as z from "zod"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "src/redux/hooks"
import { useEffect, useState } from "react"
import Select from 'react-select';
import { createQuestion } from "src/view-models/Question"

const formSchema = z.object({
  question: z.string(),
  type: z.string(),
  category: z.string().optional(),
  operator_flag_reponse: z.string().optional(),
  condition_flag_reponse: z.any().optional(),
  flag_reponse: z.string().optional(),
  operator_bilan_bilogique: z.string().optional(),
  condition_bilan_bilogique: z.any().optional(),
  bilan_bilogique: z.string().optional(),
  question_to: z.string().optional(),
  isFlag: z.boolean().default(false).optional(),
  isBilan: z.boolean().default(false).optional(),
  possibles_answers: z.array(z.string()).optional(),
  type_flag: z.any().optional(),
})

type NewQstFormInputs = z.infer<typeof formSchema>

const NewQstForm = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()
  const navigation = useNavigate()
  const { register, control, getValues, watch, setValue, handleSubmit, formState: { errors, isSubmitting }, } = useForm<NewQstFormInputs>({
    resolver: zodResolver(formSchema),
  });
  const [answerOptions, setAnswerOptions] = useState<any>([])
  const {
    fields,
    append,
    update,
    remove,
  } = useFieldArray({
    control,
    //@ts-ignore
    name: "possibles_answers"
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    let thevalues = { ...values, isFlag: undefined, isBilan: undefined }
    if (watch('question_to') === 'patient' && !values.category) {
      toast({
        description: `vous devez sélectionner une catégorie`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top'
      })
      return null
    }
    const response: any = await createQuestion(thevalues, toast, dispatch)
    if (response.status === true) {
      navigation("/admin/questions")
    } else {
      return null
    }
  }
  
  const typeOptions = [
    { value: 'checkbox', label: 'checkbox' },
    { value: 'selectbox', label: 'selectbox' },
    { value: 'number', label: 'number' },
    { value: 'text', label: 'text' },
  ];

  const flagOptions = [
    { value: '>', label: '>' },
    { value: '<', label: '<' },
    { value: '=', label: '=' },
  ];

  const isFlagOptions = [
    { value: false, label: 'Non' },
    { value: true, label: 'Oui' },
  ];

  const QstoToOptions = [
    { value: 'patient', label: 'patient' },
    { value: 'sage-femme', label: 'sage-femme' },
  ];

  const categoryOptions = [
    { value: 'Généralités', label: 'Généralités' },
    { value: 'Concernant votre grossesse actuelle', label: 'Concernant votre grossesse actuelle' },
    { value: 'Concernant votre santé en dehors de la grossesse', label: 'Concernant votre santé en dehors de la grossesse' },
  ];

  const FlagTypeOptions = [
    { value: 'ASA', label: 'ASA' },
    { value: 'Autre', label: 'Autre' },
    { value: 'Grossesse à risque', label: 'Grossesse à risque' },
    { value: 'diathèse hémorragique', label: 'diathèse hémorragique' },
  ];

  useEffect(() => {
    const options = watch('possibles_answers')?.map((option) => ({
      value: option,
      label: option
    }))
    setAnswerOptions(options)
  }, [getValues('possibles_answers')])

  const handleChangeType = (e: any) => {
    setValue('type', e.value)
  }

  const handleChangeFlagType = (options: any) => {
    const values = options?.map((o: any) => (o.value.toString()))
    setValue('type_flag', values)
  }

  const handleChangeFlag = (e: any) => {
    setValue('operator_flag_reponse', e.value)
  }

  const handleChangeBilan = (e: any) => {
    setValue('operator_bilan_bilogique', e.value)
  }

  const handleChangeIsFlag = (e: any) => {
    setValue('isFlag', e.value)
  }

  const handleChangeIsBilan = (e: any) => {
    setValue('isBilan', e.value)
  }

  const handleChangeQstTo = (e: any) => {
    setValue('question_to', e.value)
  }

  const handleChangeCategory = (e: any) => {
    setValue('category', e.value)
  }

  const handleChangeFlagReponse = (options: any) => {
    setValue('condition_flag_reponse', options.value)
  }

  const handleChangeBilanReponse = (options: any) => {
    setValue('condition_bilan_bilogique', options.value)
  }


  return (
    <form className="space-y-4 h-full overflow-y-auto px-6 py-4" onSubmit={handleSubmit(onSubmit)}>
      <FormControl isRequired isInvalid={!!errors.question}>
        <FormLabel>Question</FormLabel>
        <Input
          boxShadow='sm'
          disabled={isSubmitting}
          rounded='5px'
          type='text'
          size='md'
          id='question'
          placeholder='question'
          {...register("question")}
        />
        <FormErrorMessage>
          {errors.question && errors.question.message}
        </FormErrorMessage>
      </FormControl>
      <FormControl {...register("type")} isRequired isInvalid={!!errors.type}>
        <FormLabel>Type de reponse</FormLabel>
        <Select
          placeholder='Sélectionner'
          onChange={handleChangeType}
          options={typeOptions}
        />
        <FormErrorMessage>
          {errors.type && errors.type.message}
        </FormErrorMessage>
      </FormControl>
      {fields.map((item, index) => {
        return (
          <Stack direction={['column', 'row']} key={item.id} alignItems='start'  >
            <Controller
              control={control}
              name={`possibles_answers.${index}`}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Input
                  value={value}
                  onChange={onChange}
                  placeholder='Answer'
                />
              )}
            />
            <Button colorScheme="red" type="button" onClick={() => remove(index)}>
              Suprimer
            </Button>
          </Stack>
        );
      })}
      {(watch('type') === 'selectbox' || watch('type') === 'checkbox') ? (
        <Button
          type="button"
          onClick={() => {
            append('value');
            update(fields.length, '')
          }}
        >
          Ajouter response
        </Button>
      ) : null}
      <FormControl {...register("question_to")} isRequired isInvalid={!!errors.question_to}>
        <FormLabel>qui est considéré pour cette question ?</FormLabel>
        <Select
          placeholder='Sélectionner'
          onChange={handleChangeQstTo}
          options={QstoToOptions}
        />
        <FormErrorMessage>
          {errors.question_to && errors.question_to.message}
        </FormErrorMessage>
      </FormControl>
      {watch('question_to') === 'patient' ? (
        <FormControl {...register("question_to")} isRequired isInvalid={!!errors.question_to}>
          <FormLabel>Categorie?</FormLabel>
          <Select
            placeholder='Sélectionner'
            onChange={handleChangeCategory}
            options={categoryOptions}
          />
          <FormErrorMessage>
            {errors.question_to && errors.question_to.message}
          </FormErrorMessage>
        </FormControl>
      ) : null}
      <FormControl {...register("isFlag")} isRequired isInvalid={!!errors.isFlag}>
        <FormLabel>y a-t-il une réponse de flag</FormLabel>
        <Select
          placeholder='Sélectionner'
          onChange={handleChangeIsFlag}
          options={isFlagOptions}
        />
        <FormErrorMessage>
          {errors.isFlag && errors.isFlag.message}
        </FormErrorMessage>
      </FormControl>
      {watch('isFlag') === true ? (
        <>
          <FormControl {...register("type_flag")} isRequired isInvalid={!!errors.type_flag}>
            <FormLabel>Type de flag reponse?</FormLabel>
            <Select
              placeholder='Sélectionner'
              isMulti
              onChange={handleChangeFlagType}
              options={FlagTypeOptions}
            />
          </FormControl>
          <FormControl {...register("operator_flag_reponse")} isRequired isInvalid={!!errors.operator_flag_reponse}>
            <FormLabel>operator flag reponse</FormLabel>
            <Select
              placeholder='Sélectionner'
              onChange={handleChangeFlag}
              options={flagOptions}
            />
            <FormErrorMessage>
              {errors.operator_flag_reponse && errors.operator_flag_reponse.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.condition_flag_reponse}>
            <FormLabel>Condition flag reponse</FormLabel>
            {watch('type') !== 'checkbox' && watch('type') !== 'selectbox' ? (
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='condition_flag_reponse'
                placeholder='condition_flag_reponse'
                {...register("condition_flag_reponse")}
              />
            ) : (
              <Select
                placeholder='Sélectionner'
                onChange={handleChangeFlagReponse}
                options={answerOptions}
              />
            )}
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.flag_reponse}>
            <FormLabel>flag reponse</FormLabel>
            <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='flag_reponse'
              placeholder='flag_reponse'
              {...register("flag_reponse")}
            />
            <FormErrorMessage>
              {errors.flag_reponse && errors.flag_reponse.message}
            </FormErrorMessage>
          </FormControl>
        </>

      ) : null}
      <FormControl {...register("isBilan")} isRequired isInvalid={!!errors.isBilan}>
        <FormLabel>y a-t-il une réponse de bilan bilogique</FormLabel>
        <Select
          placeholder='Sélectionner'
          onChange={handleChangeIsBilan}
          options={isFlagOptions}
        />
        <FormErrorMessage>
          {errors.isBilan && errors.isBilan.message}
        </FormErrorMessage>
      </FormControl>
      {watch('isBilan') === true ? (
        <>
          <FormControl {...register("operator_bilan_bilogique")} isRequired isInvalid={!!errors.operator_bilan_bilogique}>
            <FormLabel>operator bilan bbilogique</FormLabel>
            <Select
              placeholder='Sélectionner'
              onChange={handleChangeBilan}
              options={flagOptions}
            />
            <FormErrorMessage>
              {errors.operator_bilan_bilogique && errors.operator_bilan_bilogique.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.condition_bilan_bilogique}>
            <FormLabel>Condition flag reponse</FormLabel>
            {watch('type') !== 'checkbox' && watch('type') !== 'selectbox' ? (
              <Input
                boxShadow='sm'
                disabled={isSubmitting}
                rounded='5px'
                type='text'
                size='md'
                id='condition_bilan_bilogique'
                placeholder='condition_bilan_bilogique'
                {...register("condition_bilan_bilogique")}
              />
            ) : (
              <Select
                placeholder='Sélectionner'
                onChange={handleChangeBilanReponse}
                options={answerOptions}
              />
            )}
          </FormControl>
          <FormControl isRequired isInvalid={!!errors.bilan_bilogique}>
            <FormLabel>bilan bilogique</FormLabel>
            <Input
              boxShadow='sm'
              disabled={isSubmitting}
              rounded='5px'
              type='text'
              size='md'
              id='bilan_bilogique'
              placeholder='bilan_bilogique'
              {...register("bilan_bilogique")}
            />
            <FormErrorMessage>
              {errors.bilan_bilogique && errors.bilan_bilogique.message}
            </FormErrorMessage>
          </FormControl>
        </>

      ) : null}

      <HStack mt={10}>
        <Button
          type='submit'
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          p='4'
          rounded='20px'
          mx='auto'
          w='40%'
          colorScheme='blue'
          variant='solid'
        >
          Ajouter
        </Button>
      </HStack>
    </form>
  )
}

export default NewQstForm