import { Button, Flex, Input, Stack, useToast } from '@chakra-ui/react';
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { IoMdTrash } from 'react-icons/io';
import { MdAddCircleOutline } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import { traitemetPatient } from 'src/view-models/Patient';
import * as z from "zod"

const formSchema = z.object({
  patient_id:z.number(),
  treatments: z.array(z.string().min(1))
})

type NewQstFormInputs = z.infer<typeof formSchema>

const PatienteTraitementForm = () => {
 const formRef = useRef<any>(null);
   const {id} = useParams()
   const toast = useToast()
   const [isInForm,setIsInForm] = useState(false)
   const [isInTraitments,setIsInTraitments] = useState(false)

    const {treatments:sageFemmeTraitments} = useAppSelector((state)=>state.question)

  const { control, handleSubmit, watch,getValues, formState: { isSubmitting }, } = useForm<NewQstFormInputs>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      patient_id:Number(id),
      treatments:sageFemmeTraitments && sageFemmeTraitments?.length > 0 ? sageFemmeTraitments : ["Vitamines de grossesse", "Fer"]
    }
  });

  const { fields, append, update, remove, } = useFieldArray({
    control,
    //@ts-ignore
    name: "treatments"
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    await traitemetPatient(values,toast)
  }

  useEffect(() => {
      async function handleClickOutside(event:any) {
        
        if (formRef.current && !formRef.current.contains(event.target) && isInForm) {
          try {
            setIsInTraitments(true)
            const values = {
              treatments:getValues('treatments'),
              patient_id:Number(id),
            }
            await traitemetPatient(values,toast)
            setIsInForm(false)
          } catch (error) {
            
          }finally{
            setIsInTraitments(false)
          }
        }
      }
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [formRef,isInForm]);

    const HandleSetInForm = () => {
      setIsInForm(true)
    }

  return (
    <>
      <form className="space-y-4 h-full overflow-y-auto py-4" ref={formRef} onClick={HandleSetInForm} onSubmit={handleSubmit(onSubmit)}>
        {
          fields.map((item, index) => {
            return (
              <Stack direction={['column', 'row']} key={item.id} alignItems='start'  >
                <Controller
                  control={control}
                  name={`treatments.${index}`}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      value={value}
                      onChange={onChange}
                      placeholder='Answer'
                      disabled={isSubmitting || isInTraitments}
                    />
                  )}
                />
                <Flex alignItems={'center'} gap={2}>
                  <Button disabled={isSubmitting || isInTraitments} px={0} colorScheme="red" type="button" onClick={() => remove(index)}>
                    <IoMdTrash />
                  </Button>
                  {index === fields?.length - 1 && (
                    <Button
                    disabled={isSubmitting || isInTraitments}
                      px={2}
                      colorScheme="blue"
                      type="button"
                      onClick={() => {
                        append('value');
                        update(fields.length, '')
                      }}
                    >
                      <MdAddCircleOutline size={20} />
                    </Button>
                  )}
                </Flex>
              </Stack>
            );
          })
        }
        {fields?.length === 0 && (
          <Button
          disabled={isSubmitting || isInTraitments}
            px={2}
            colorScheme="blue"
            type="button"
            onClick={() => {
              append('value');
              update(fields.length, '')
            }}
          >
            <MdAddCircleOutline size={20} />
          </Button>
        )}
        <Button type='submit' isDisabled={isSubmitting || isInTraitments || fields?.length === 0 || watch("treatments")?.some((i) => !i)} colorScheme='blue' w='100%'>Ajouter</Button>
      </form >
    </>
  )
}

export default PatienteTraitementForm