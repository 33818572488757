import { Box, Button, Checkbox, FormControl, FormLabel, Input, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { setSeigefemmeValidating } from 'src/redux/features/question/questionSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import ValidateResponse from 'src/pages/sage-femme/patients/new/components/ValidateResponse'

const QuestionForm = ({ id }: { id: string }) => {
  const dispatch = useAppDispatch()
  const { sageFemmeQuestions: questions } = useAppSelector((state) => state.question)
  const { isSeigefemmeValidating } = useAppSelector((state) => state.question)
  const itemsPerPage = 3;
  const lastPage = Math.ceil(questions?.length / itemsPerPage)

  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const displayedItems = questions?.slice(startIndex, endIndex);
  const [responses, setRespones] = useState<any>([])

  useEffect(() => {
    const values = questions?.map((q) => ({
      id: q.question_id,
      value: q.question,
      type: q.type,
      answers: ((q?.question_id === 3 || q?.question_id === 6) && q?.client_answers === null) ? [] : q.client_answers,
      flag_response:
        q?.question_id === 11 ? q.flag_reponse
          :
          q?.operator_flag_reponse === ">" ? q?.question_id === 3 ? (q?.client_answers[0]?.id === 5 ? Number(q?.client_answers[0]?.answer) > q?.condition_flag_reponse ? q.flag_reponse : null : Number(q?.client_answers[1]?.answer) > q?.condition_flag_reponse ? q.flag_reponse : null)
            :
            q?.question_id === 5 ? (q?.client_answers[0]?.id === 6 ? (Number(q?.client_answers[0]?.answer) >= 140 || Number(q?.client_answers[1]?.answer) >= 90 ? q?.flag_reponse : null) : (Number(q?.client_answers[0]?.answer) >= 90 || Number(q?.client_answers[1]?.answer) >= 140 ? q?.flag_reponse : null)) :
              (Number(q?.client_answers[0]?.answer) > q?.condition_flag_reponse ? q.flag_reponse : null) :
            q?.operator_flag_reponse === "<" ? q?.question_id === 6 ? (q?.client_answers[0]?.id === 7 ? Number(q?.client_answers[0]?.answer) < q?.condition_flag_reponse ? q.flag_reponse : null : Number(q?.client_answers[1]?.answer) < q?.condition_flag_reponse ? q.flag_reponse : null) :
              (Number(q?.client_answers[0]?.answer) < q?.condition_flag_reponse ? q.flag_reponse : null) :
              q?.operator_flag_reponse === "=" ? (q?.client_answers[0]?.answer == q?.condition_flag_reponse ? q.flag_reponse : null) : null,
      bilan_bilogique:
        q?.operator_bilan_bilogique === ">" ? Number(q?.client_answers[0]?.answer) > q?.condition_bilan_bilogique ? q.bilan_bilogique : null :
          q?.operator_bilan_bilogique === "<" ? q?.question_id === 6 ? (q?.client_answers[0]?.id === 7 ? Number(q?.client_answers[0]?.answer) < q?.condition_bilan_bilogique ? q.bilan_bilogique : null : Number(q?.client_answers[1]?.answer) < q?.condition_bilan_bilogique ? q.bilan_bilogique : null) : (Number(q?.client_answers[0]?.answer) < q?.condition_bilan_bilogique ? q.bilan_bilogique : null) :
            q?.operator_bilan_bilogique === "=" ? (q?.client_answers[0]?.answer == q?.condition_bilan_bilogique ? q.bilan_bilogique : null) : null,
      type_flag: q?.type_flag ? q.type_flag?.map((t: any) => t.type) : null
    }))
    setRespones(values)
  }, [])

  const handleSubmit = (e: any) => {
    e.preventDefault()
    const theValues = responses?.map((r: any) => r?.id === 11 ?
      (r?.answers?.length > 0 ?
        r?.answers?.find((a: any) => a?.answer?.toLowerCase() === 'non') ? { ...r, flag_response: null } :
          r?.answers?.length === 2 ? { ...r, flag_response: `${r?.answers[0]?.answer},${r?.answers[1]?.answer}` }
            : { ...r, flag_response: r?.answers[0]?.answer }
        : { ...r, flag_response: null })
      : r
    )

    const data = {
      id,
      query: theValues
    }
    dispatch(setSeigefemmeValidating(data))
  };


  const handleClickNext = (e: any) => {
    e.preventDefault()

    if (currentPage + 1 !== lastPage) {
      const newPage = currentPage + 1;
      if (newPage < Math.ceil(questions?.length / itemsPerPage)) {
        setCurrentPage(newPage);
      }
    } else {
      const theValues = responses?.map((r: any) => r?.id === 11 ?
        (r?.answers?.length > 0 ?
          r?.answers?.find((a: any) => a?.answer?.toLowerCase() === 'non') ? { ...r, flag_response: null } :
            r?.answers?.length === 2 ? { ...r, flag_response: `${r?.answers[0]?.answer},${r?.answers[1]?.answer}` }
              : { ...r, flag_response: r?.answers[0]?.answer }
          : { ...r, flag_response: null })
        : r
      )
      const data = {
        id,
        query: theValues
      }
      dispatch(setSeigefemmeValidating(data))
    }
  };

  const handleClickPrev = () => {
    const newPage = currentPage - 1;
    if (newPage >= 0) {
      setCurrentPage(newPage);
    }
  };






  const handleChange = async (e: any, index: number, type: string, answerId: number | null, questionId: number, multipleType: string | null) => {
    const theQuestion = questions?.find((q: any) => multipleType === 'gestite' ? q?.question_id === questionId - 1 : multipleType === 'parite' ? q?.question_id === questionId - 2 : q?.question_id === questionId)
    if (type === 'input') {
      setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, answers: [{ id: null, answer: e.target.value }] } : item))
    } else if (type === 'radio') {
      setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, answers: [{ id: answerId, answer: e.target.value }] } : item))
    } else if (type === 'checkbox') {
      if (e.target.checked) {
        setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, answers: [...item.answers, { id: answerId, answer: e.target.value }] } : item))
      } else {
        setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, answers: item.answers.filter((a: { id: number, answer: string }) => a.answer !== e.target.value) } : item))
      }
    }
    else if (type = 'multipleText') {
      if (multipleType === 'gestite') {
        const newResponses = responses?.map((item: any, index1: number) => index1 === index ? { ...item, answers: e.target.value ? item.answers?.map((i: any) => i?.id === questionId ? { id: questionId, answer: e.target.value } : i) : item.answers.filter((a: any) => a.id !== questionId) } : item)
        const isThereResponse = newResponses?.some((item: any, index1: number) => index1 === index && item.answers?.some((i: any) => i.id === questionId))
        if (!isThereResponse) {
          if (e.target.value !== '') {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, answers: [...item.answers, { id: questionId, answer: e.target.value }] } : item))
          } else {
            setRespones(newResponses)
          }
        } else {
          setRespones(newResponses)
        }
      } else if (multipleType === 'parite') {
        const newResponses = responses?.map((item: any, index1: number) => index1 === index ? { ...item, answers: e.target.value ? item.answers?.map((i: any) => i?.id === questionId ? { id: questionId, answer: e.target.value } : i) : item.answers.filter((a: any) => a.id !== questionId) } : item)
        const isThereResponse = newResponses?.some((item: any, index1: number) => index1 === index && item.answers?.some((i: any) => i.id === questionId))
        if (!isThereResponse) {
          if (e.target.value !== '') {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, answers: [...item.answers, { id: questionId, answer: e.target.value }] } : item))
          } else {
            setRespones(newResponses)
          }
        } else {
          setRespones(newResponses)
        }
      }
    }

    if (!multipleType) {
      if (theQuestion.flag_reponse) {
        if (theQuestion.operator_flag_reponse === ">") {
          if (Number(e.target.value) > Number(theQuestion.condition_flag_reponse)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: theQuestion.flag_reponse } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: null } : item))
          }
        } else if (theQuestion.operator_flag_reponse === "<") {
          if (Number(e.target.value) < Number(theQuestion.condition_flag_reponse)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: theQuestion.flag_reponse } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: null } : item))
          }
        } else {
          if (e.target.value == theQuestion.condition_flag_reponse) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: theQuestion.flag_reponse } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: null } : item))
          }
        }
      }
      if (theQuestion.bilan_bilogique) {
        if (theQuestion.operator_bilan_bilogique === ">") {
          if (Number(e.target.value) > Number(theQuestion.condition_bilan_bilogique)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: theQuestion.bilan_bilogique } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: null } : item))
          }
        } else if (theQuestion.operator_bilan_bilogique === "<") {
          if (Number(e.target.value) < Number(theQuestion.condition_bilan_bilogique)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: theQuestion.bilan_bilogique } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: null } : item))
          }
        } else {
          if (e.target.value == theQuestion.condition_bilan_bilogique) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: theQuestion.bilan_bilogique } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: null } : item))
          }
        }
      }
    } else {
      if (theQuestion.flag_reponse && ((questionId === 5 && multipleType === 'parite') || (questionId === 7 && multipleType === 'gestite') || (questionId === 6 && multipleType === 'gestite') || (questionId === 7 && multipleType === 'parite'))) {
        if (theQuestion.operator_flag_reponse === ">") {
          if ((questionId === 5 && multipleType === 'parite') && Number(e.target.value) > Number(theQuestion.condition_flag_reponse)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: theQuestion.flag_reponse } : item))
          } else if ((questionId === 6 && multipleType === 'gestite' && Number(e.target.value) >= 140 || (responses[index]?.answers[0]?.id === 7 ? responses[index]?.answers[0]?.answer >= 90 : responses[index]?.answers[1]?.answer >= 90)) || (questionId === 7 && multipleType === 'parite' && Number(e.target.value) >= 90 || (responses[index]?.answers[0]?.id === 6 ? responses[index]?.answers[0]?.answer >= 140 : responses[index]?.answers[1]?.answer >= 140))) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: theQuestion.flag_reponse } : item))
          }
          //Here prblm
          else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: null } : item))
          }
        } else if (theQuestion.operator_flag_reponse === "<") {
          if ((questionId === 7 && multipleType === 'gestite') && Number(e.target.value) < Number(theQuestion.condition_flag_reponse)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: theQuestion.flag_reponse } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: null } : item))
          }
        } else {
          if (((questionId === 5 && multipleType === 'parite') || (questionId === 7 && multipleType === 'gestite')) && e.target.value == theQuestion.condition_flag_reponse) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: theQuestion.flag_reponse } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, flag_response: null } : item))
          }
        }
      }
      if (theQuestion.bilan_bilogique && questionId === 7 && multipleType === 'gestite') {
        if (theQuestion.operator_bilan_bilogique === ">") {
          if (Number(e.target.value) > Number(theQuestion.condition_bilan_bilogique)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: theQuestion.bilan_bilogique } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: null } : item))
          }
        } else if (theQuestion.operator_bilan_bilogique === "<") {
          if ((questionId === 7 && multipleType === 'gestite') && Number(e.target.value) < Number(theQuestion.condition_bilan_bilogique)) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: theQuestion.bilan_bilogique } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: null } : item))
          }
        } else {
          if (e.target.value == theQuestion.condition_bilan_bilogique) {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: theQuestion.bilan_bilogique } : item))
          } else {
            setRespones((prev: any) => prev.map((item: any, index1: number) => index1 === index ? { ...item, bilan_bilogique: null } : item))
          }
        }
      }
    }

  }

  return (
    <Box flex={4} my={4} >
      {isSeigefemmeValidating ? (
        <ValidateResponse />
      ) : null}
      <form className='space-y-4' onSubmit={handleSubmit}>
        {displayedItems?.map((question: any, index: number) => (
          <FormControl key={index} >
            <FormLabel>{question.question}</FormLabel>
            {question.type === 'text' || question.type === 'number' || question.type === 'date' ? (
              <Input
                boxShadow='sm'
                rounded='5px'
                type={question.type === 'date' ? 'date' : question.type === 'text' ? 'text' : 'number'}
                size='md'
                value={responses[currentPage * itemsPerPage + index]?.answers[0]?.answer ? responses[currentPage * itemsPerPage + index]?.answers[0]?.answer : ''}
                placeholder='Reponse...'
                onChange={(e) => handleChange(e, currentPage * itemsPerPage + index, 'input', null, question?.question_id, null)}
              />
            ) : question?.type === 'multipletText' ? (
              <Stack direction={{ base: 'column', sm: 'row' }} >
                <Box flex={1} >
                  <FormLabel>{question?.question_id === 3 ? 'Gestité' : question?.question_id === 5 ? 'Pression artérielle systolique (mmHG)' : 'Plaquettes (G/L)'}</FormLabel>
                  <Input
                    boxShadow='sm'
                    rounded='5px'
                    type='number'
                    size='md'
                    value={responses[currentPage * itemsPerPage + index]?.answers[0]?.id === question?.question_id + 1 ? responses[currentPage * itemsPerPage + index]?.answers[0]?.answer : responses[currentPage * itemsPerPage + index]?.answers[1]?.id === question?.question_id + 1 ? responses[currentPage * itemsPerPage + index]?.answers[1]?.answer : ''}
                    placeholder='Reponse...'
                    onChange={(e) => handleChange(e, currentPage * itemsPerPage + index, 'multipleText', null, question?.question_id + 1, 'gestite')}
                  />
                </Box>
                <Box flex={1} >
                  <FormLabel>{question?.question_id === 3 ? 'Parité' : question?.question_id === 5 ? 'Pression artérielle diastolique (mmHG)' : 'Hémoglobine (g/dL)'}</FormLabel>
                  <Input
                    boxShadow='sm'
                    rounded='5px'
                    type='number'
                    size='md'
                    value={responses[currentPage * itemsPerPage + index]?.answers[0]?.id === question?.question_id + 2 ? responses[currentPage * itemsPerPage + index]?.answers[0]?.answer : responses[currentPage * itemsPerPage + index]?.answers[1]?.id === question?.question_id + 2 ? responses[currentPage * itemsPerPage + index]?.answers[1]?.answer : ''}
                    placeholder='Reponse...'
                    onChange={(e) => handleChange(e, currentPage * itemsPerPage + index, 'multipleText', null, question?.question_id + 2, 'parite')}
                  />
                </Box>
              </Stack>
            ) : question.type === 'selectbox' ? (
              <Stack spacing={5} direction='column'>
                {question?.possibles_answers?.map((answer: { id: number, answer: string }, index2: any) => {
                  return (
                    <Radio isChecked={responses[currentPage * itemsPerPage + index]?.answers[0]?.answer === answer?.answer} onChange={(e) => handleChange(e, currentPage * itemsPerPage + index, 'radio', answer.id, question?.question_id, null)} value={answer.answer} key={index2} >
                      {answer.answer}
                    </Radio>
                  )
                })}
              </Stack>
            ) : (
              <Stack spacing={5} direction='column'>
                {question.possibles_answers?.map((answer: { id: number, answer: string }, index2: number) => (
                  <Checkbox colorScheme='blue' isChecked={responses[currentPage * itemsPerPage + index]?.answers?.some((s: any) => s.answer === answer.answer)} onChange={(e) => handleChange(e, currentPage * itemsPerPage + index, 'checkbox', answer.id, question?.question_id, null)} value={answer.answer} key={index2} >{answer.answer}</Checkbox>
                ))}
              </Stack>
            )
            }
          </FormControl>
        ))}
        <Stack direction='row' mt={4} justifyContent='space-between' >
          <Button type='submit' variant='ghost' colorScheme='blue' onClick={handleSubmit}>Enregistrer les réponses</Button>
          <Stack direction={{ base: 'column', md: 'row' }}>
            {currentPage !== 0 && (
              <Button type='button' px={10} variant='outline' borderColor='black' _hover={{ bg: 'blue.500', borderColor: 'blue.500', color: 'white' }} onClick={handleClickPrev}>Précédent</Button>
            )}
            <Button type='button' px={10} colorScheme='blue' onClick={handleClickNext} >{currentPage + 1 === lastPage ? "Terminer" : `Continuer (${currentPage + 1}/${lastPage})`}</Button>
          </Stack>

        </Stack>
      </form>


    </Box>
  )
}

export default QuestionForm