import {Flex, HStack, Stack, Text, VStack } from '@chakra-ui/react'
import { useState } from 'react'

const QuestionComp = ({question}:{question:any}) => {
    const [showComment,setIsShowComment] = useState(false)

  return (
    <Stack direction={showComment?'column' : 'row'} alignItems='flex-start' >
            <VStack alignItems='start' w={showComment ? 'fit-content' : '100%'} >
                <HStack>
                    <Text fontSize={16} >{question?.medecin_text}</Text>
                        { !question?.answer || question?.answer.length === 0 ? (
                            <Text fontWeight='bold' color='red'> ne rien mettre comme texte</Text>
                        ) : 
                            question?.question_id !== 5 && question?.question_id !== 6 ? (
                                question?.answer?.map((answer:any,index:number)=>(
                                    <Text as="span" fontSize={{base:'14',sm:'16'}}  key={index}  fontWeight='bold' > {answer?.answer} {index !== question?.answer?.length -1 && "/"}</Text>
                                ))
                            ) : question?.question_id === 5 ? (
                                <Flex>
                                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`${question?.answer[0]?.id === 6 ? question?.answer[0]?.answer : question?.answer[1]?.answer} s/`}</Text>
                                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`${question?.answer[0]?.answer === 7 ? question?.answer[0]?.answer : question?.answer[1]?.answer} d`}</Text>
                                </Flex>
                            ) : question?.question_id === 6 ? (
                                <Flex>
                                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`${question?.answer[0]?.id === 8 ? question?.answer[0]?.answer : question?.answer[1]?.answer} g/dl-`}</Text>
                                    <Text fontSize={{base:'14',sm:'16'}} fontWeight='bold' >{`${question?.answer[0]?.id === 7 ? question?.answer[0]?.answer : question?.answer[1]?.answer} G/L`}</Text>
                                </Flex>
                            ) : null
                        }
                   
                </HStack>
                {question?.is_commented === 1 ? (
                <HStack>
                    <Text fontSize={16} >Votre commentaire :</Text>
                    <Text fontSize={16} fontWeight='bold' color='#BB6BD9' >{question?.comment}</Text>
                </HStack>
            ) : null}
            </VStack>
        {/* {
            question?.to_comented === 1 ? (
                <Box w={showComment ? '100%' : 'fit-content'} >
                    <Button mb={2} onClick={handleShowComment} >{ showComment ? "Fermer" : question?.is_commented === 1 ? "Modifier le commentaire":"Commenter"}</Button>
                {showComment ? (
                    <CommentInput comment={question?.comment} questionId={question?.question_id} setShowComment={setShowComment} />
                ) : null}
                </Box>
            ) : null
        } */}
    </Stack>
  )
}

export default QuestionComp