import { Box, Button, Flex } from '@chakra-ui/react'
import { useState } from 'react'
import { openValidCPA } from 'src/redux/features/practitioner/practitionerSlice'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import EditConclusion from '../EditConclusion'

const Validate = ({ patientId, setActiveTabIndex }: { patientId: string, setActiveTabIndex: any }) => {
  const dispatch = useAppDispatch()
  const { isValidate } = useAppSelector((state) => state.practitioner)
  const [isLoading, setIsLoading] = useState(false)

  const handleOpenValidate = () => {
    dispatch(openValidCPA())
  }

  const handleGetPDF = async () => {
    window.open(`${process.env.REACT_APP_HOST}/api/medecin/patient/get-pdf-cpa/${patientId}`, '_blank')
  }

  const goToConsultation = () => {
    setActiveTabIndex(1)
  }

  return (
    <Box backgroundColor='white' w='full' bottom={10} py={6} px={4} zIndex={100} my={4} boxShadow='md' >
      <Flex justifyContent='space-between' gap={4} >
        {isValidate !== 1 ? (
          <EditConclusion />
        ) : null}
        <Flex justifyContent='flex-end' gap={4}>
          {isValidate === 0 ? (
            <Button isLoading={isLoading} variant='outline' borderWidth={2} _hover={{ bg: '#235EE8', color: "white" }} borderColor='#235EE8' onClick={handleOpenValidate} >Valider le dossier</Button>
          ) : (
            <Button isLoading={isLoading} variant='outline' borderWidth={2} _hover={{ bg: '#235EE8', color: "white" }} borderColor='#235EE8' onClick={handleGetPDF} >Exporter la fiche de synthèse au format PDF</Button>
          )}
          <Button isDisabled={isLoading} variant='solid' colorScheme='blue' onClick={goToConsultation} >Commencer la consultation</Button>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Validate