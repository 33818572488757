import { Box, Button, VStack } from "@chakra-ui/react"
import { FaBars } from "react-icons/fa6"

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from '@chakra-ui/react'
import { useRef, useState } from "react"
import { navs } from "./constants"


const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false)

  const btnRef = useRef<any>()

  const openDrawer = () => {
    setIsOpen(true)
  }

  const closeDrawer = () => {
    setIsOpen(false)
  }

  const scrollToSection = (label: string) => {

    const section = document.getElementById(label);
    section!.scrollIntoView({ behavior: 'smooth' });

    closeDrawer()

  };

  const goToCalendar = () => {
    window.open('https://calendly.com/focuscareio', '_blank', 'noopener,noreferrer')
  }

  return (
    <Box display={{ base: 'block', lg: 'none' }} >
      <Button px={0} ref={btnRef} variant={'ghost'} onClick={openDrawer}>
        <FaBars fontSize={35} />
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={closeDrawer}
        autoFocus={false}
        returnFocusOnClose={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader></DrawerHeader>
          <DrawerBody>
            <VStack mt={4}>
              {navs?.map((nav, index) => (
                <Button w={'200px'} h='45px' key={index} fontSize={'18px'} fontWeight={400} color={'#182F45'} onClick={() => scrollToSection(nav.href)}>{nav.label}</Button>
              ))}
              <Button w={'200px'} h='45px' fontSize={'18px'} fontWeight={400} color={'#182F45'} onClick={goToCalendar} >Programmer une démo</Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  )
}

export default MobileNav