import { Box, Flex, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Header from './components/Header'
import Questions from './components/questions/Questions'
import Commentaire from './components/commentaire/Commentaire'
import { getSeigeFemmeQuestions } from 'src/view-models/Question'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import Loading from 'src/components/loading/Loading'
import PatienteTraitement from './components/patiente-traitement/PatienteTraitement'

const Consulter = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { sageFemmeQuestions: questions } = useAppSelector((state) => state.question)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchQuestions = async () => {
      await getSeigeFemmeQuestions(Number(id!), dispatch)
      setIsLoading(false)
    }
    fetchQuestions()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  if (!isLoading && questions?.length === 0) {
    return <Box backgroundColor='white' boxShadow='md' rounded={10} w={{ base: '90%', sm: '80%', lg: '70%' }} mx='auto' my={6} p={4}>
      <Text fontWeight='bold' >Assurez-vous de cibler le bon patient!</Text>
      <Link to={`/sage-femme/patients`} className='underline'>Retour à la page des patients</Link>
    </Box>
  }

  return (
    <Box px={4} py={2} rounded={4}>
      <Header />
      <Flex flexDirection={{ base: 'column', lg: 'row' }} gap={4} my={10}>
        <Questions isLoading={isLoading} />
        <Box flex={2}>
          <Commentaire />
          <PatienteTraitement />
        </Box>
      </Flex>
    </Box>
  )
}

export default Consulter