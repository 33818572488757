import Patient, { PatientInfo } from "src/models/Patient"
import { setPatient, setPatients, setSendMail, unsetSendMail } from "src/redux/features/patient/patientSlice"
import { setCPA } from "src/redux/features/practitioner/practitionerSlice"
import { setConclusionCPA } from "src/redux/features/question/questionSlice"

export const createPatient = async (PatientInfos:PatientInfo,toast:any,dispatch:any) => {
    const response : any = await Patient.createPatient(PatientInfos)
    if(response.status === 201) {
        toast({
            description: `Le patient a été créé avec succès!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true,id:response.data.data.id}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const updatePatient = async (id:string,role:string|undefined,PatientInfos:PatientInfo,toast:any,dispatch:any) => {
    const response : any = await Patient.updatePatient(id,PatientInfos)
    if(response.status === 200) {
        
    const response2 :any = await Patient.getPatient(response.data.data.id,role)
    if(response2.status === 200 ){
        dispatch(setPatient(response2?.data?.data))
    }
    toast({
        description: `Patient a été mis à jour avec succès !`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getPatients = async (role:string | undefined,dispatch : any) => {
    const response : any = await Patient.getPatients(role)
    if(response.status === 200) {
        dispatch(setPatients(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getArchivedPatients = async (role:string|undefined,dispatch : any) => {
    const response : any = await Patient.getArchivedPatients(role)
    if(response.status === 200) {
        dispatch(setPatients(response.data.data.data))
        return {status:true}
    }else {
        return {status:false}
    }
}

export const getPatient = async (id:string,role:string| undefined,dispatch : any) => {
    const response : any = await Patient.getPatient(id,role)
    if(response.status === 200) {
        dispatch(setPatient(response.data.data))
        return {status:true}
    }else{
        return {status:false}
    }
}

export const deletePatient = async (id:string,toast:any) => {
    const response : any = await Patient.deletePatient(id)
    if(response.status === 200) {
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const sendMail = async (id:string,dispatch:any,toast : any) => {
    dispatch(setSendMail())
    const response : any = await Patient.sendMail(id)
    if(response.status === 200) {
        toast({
            description: `Email a été envoyé au patient!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
        dispatch(unsetSendMail())
        return {status:true}
    }else{
        dispatch(unsetSendMail())
        return {status:false}
    }
}

export const getCPA = async (id:string,dispatch:any) => {
    const response : any = await Patient.getCPA(id)
    if(response.status === 200){
        dispatch(setCPA(response?.data?.data))
        return {status:true}
    }else{
        return {status:false}
    }
}

export const commentSagefemme = async (values:any,toast:any) => {
    const response : any = await Patient.sageFemmeComment(values)
    if(response.status === 201) {
        toast({
            description: `commentaire inséré avec succès`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const traitemetPatient = async (values:any,toast:any) => {
    const response : any = await Patient.sageFemmeTraitement(values)
    if(response.status === 200) {
        toast({
            description: `traitement inséré avec succès`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const setIsWatchedVideo = async (id:any,toast:any) => {
    const response : any = await Patient.setIsWatchedVideo(id)
    if(response.status === 201) {
          return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const getConclusionCPA = async (id:string,dispatch:any) => {
    const response : any = await Patient.conclusionCPA(id)
    if(response.status === 200) {
        dispatch(setConclusionCPA(response.data.data))
        return {status:true}
    }else{
        return {status:false}
    }
}

export const updateConclusionCPA = async (id:string,values:any,toast:any,dispatch:any,setIsEdit:any) => {
    const response : any = await Patient.updateConclusionCPA(id,values)
    if(response.status === 200) {
        
    const response2 :any = await Patient.conclusionCPA(id)
    if(response2.status === 200 ){
        dispatch(setConclusionCPA(response.data.data))
    }
    const res3:any = await Patient.getCPA(id)
    if(res3?.status === 200){
        dispatch(setCPA(res3?.data?.data))
    }
        setIsEdit()
    toast({
        description: `Conclusion CPA a été mise à jour avec succès !`,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position:'top'
        })
        return {status:true}
    }else if (response.status !==500){
        toast({
            description: `${response?.message?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
          return {status:false}
    }else {
        toast({
        description: `quelque chose s'est mal passé !`,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position:'top'
      })
      return {status:false}
    }
}

export const validateCPA = async (id:string,toast : any) => {
    const response : any = await Patient.validateCPA(id)
    if(response.status === 201) {
        toast({
            description: `${response?.data?.message}`,
            status: 'success',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
        return {status:true}
  }else if (response.status !==500){
      toast({
          description: `${response?.message?.message}`,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position:'top'
        })
        return {status:false}
  }else {
      toast({
      description: `quelque chose s'est mal passé !`,
      status: 'error',
      duration: 3000,
      isClosable: true,
      position:'top'
    })
    return {status:false}
  }
}


export const filterPatients = async (values:any,dispatch:any,toast:any) => {
    const response : any = await Patient.filterPatients(values)
    if(response.status === 200) {
        dispatch(setPatients(response.data.data.data))
        return {status:true}
    }else {
        toast({
            description: `quelque chose s'est mal passé !`,
            status: 'error',
            duration: 3000,
            isClosable: true,
            position:'top'
          })
        return {status:false}
    }
}