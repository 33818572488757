import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getCPA, getPatient } from 'src/view-models/Patient'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks'
import Loading from 'src/components/loading/Loading'
import ProfileComp from './components/ProfileComp'
import Consulter from './consultation/Consulter'

const Profile = () => {
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const { patient } = useAppSelector((state) => state.patient)
    const [isLoadingPatient, setIsLaodingPatient] = useState(false)
    const [isLoadingCPA, setIsLaodingCPA] = useState(false)

    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleTabChange = (index: number) => {
        setActiveTabIndex(index);
    };

    useEffect(() => {
        const fetchPatient = async () => {
            setIsLaodingPatient(true)
            await getPatient(id!, 'medecin', dispatch)
            setIsLaodingPatient(false)
        }

        fetchPatient()
    }, [id])



    useEffect(() => {
        const fetchCPA = async () => {
            setIsLaodingCPA(true)
            await getCPA(id!, dispatch)
            setIsLaodingCPA(false)
        }
        fetchCPA()
    }, [id])

    if (isLoadingPatient || isLoadingCPA) {
        return <Loading />
    }

    if (!isLoadingPatient && !isLoadingCPA && !patient) {
        return <Box backgroundColor='white' boxShadow='md' rounded={10} w={{ base: '90%', sm: '80%', lg: '70%' }} mx='auto' my={6} p={4}>
            <Text fontWeight='bold' >Assurez-vous de cibler le bon patient!</Text>
            <Link to={`/medecin/patients`} className='underline'>Retour à la page des patients</Link>
        </Box>
    }

    return (
        <Tabs isFitted index={activeTabIndex} onChange={handleTabChange}>
            <TabList mb='1em'>
                <Tab>Profil du patient</Tab>
                <Tab>Consultation</Tab>
            </TabList>
            <TabPanels>
                <TabPanel px={0}>
                    <ProfileComp setActiveTabIndex={setActiveTabIndex} />
                </TabPanel>
                <TabPanel px={0}>
                    <Consulter />
                </TabPanel>
            </TabPanels>
        </Tabs>
    )
}

export default Profile