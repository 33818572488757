import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react'
import DataTable from 'src/components/tables/DataTable';
import { colDefs } from './constants';
import { useNavigate } from 'react-router-dom';
import { disactivePractitioner, getPractitionersByRole } from 'src/view-models/Practitioner';
import Loading from 'src/components/loading/Loading';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import DeleteModal from './components/DeleteModal';

const Assistants = () => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const { practitioners: assistants, status } = useAppSelector((state) => state.practitioner)
  const [isLoading, setIsLoading] = useState(true)

  const goToAddPractitioner = () => {
    navigation('/admin/assistants/new')
  }

  useEffect(() => {
    const fetchAssistants = async () => {
      if (status === 1) {
        setIsLoading(true)
        await getPractitionersByRole("assistant", dispatch)
      } else {
        setIsLoading(true)
        await disactivePractitioner("assistant", dispatch)
      }
      setIsLoading(false)
    }
    fetchAssistants()
  }, [status])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Box px={4} py={2} rounded={4}  >
      <DeleteModal />
      <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems={{ base: 'flex-start', md: 'center' }} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >Assistants</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant Assistants </Text>
        </Box>
        <Button colorScheme='blue' onClick={goToAddPractitioner} >Ajouter Un Assistant</Button>
      </Flex>
      <Box my={8} >
        <DataTable columns={colDefs} data={assistants} />
      </Box>
    </Box>
  )
}

export default Assistants