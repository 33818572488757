import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react'
import DataTable from 'src/components/tables/DataTable';
import { colDefs } from './constants';
import { useNavigate } from 'react-router-dom';
import Loading from 'src/components/loading/Loading';
import { getAdmins } from 'src/view-models/Admin';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import DeleteModal from './components/DeleteModal';

const Admins = () => {
  const dispatch = useAppDispatch()
  const navigation = useNavigate()
  const [isLoading,setIsLoading] = useState(true)
  const {admins} = useAppSelector((state)=>state.admin)
    
  const goToAddAdmin = () => {
    navigation('/admin/admins/new')
  }

  useEffect(() => {
    const fetchDcotors = async () => {
      await getAdmins(dispatch)
      setIsLoading(false)
    }
    fetchDcotors()
  }, [])

  if(isLoading){
    return <Loading />
  } 


  return (
    <Box  px={4} py={2} rounded={4}  >
        <DeleteModal />
      <Flex  height={'100px'} flexDirection={{base:'column',md:'row'}} alignItems={{base:'flex-start',md:'center'}} justifyContent='space-between' gap={4} >
        <Box>
          <Text color='#4E5D78' fontWeight={700} fontSize={30} >Admins</Text>
          <Text color='#4E5D78'>Sur cette page, vous pouvez gérer tous les aspects concernant admins </Text>
        </Box>
        <Button colorScheme='blue' onClick={goToAddAdmin} >Ajouter Un admin</Button>
      </Flex>
      <Box my={8} >
        <DataTable columns={colDefs} data={admins} />
      </Box>
    </Box>
)
}

export default Admins