import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, HStack, Input, useToast } from "@chakra-ui/react"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useNavigate } from "react-router-dom"
import { createPractitioner } from "src/view-models/Practitioner"
import { useAppDispatch, useAppSelector } from "src/redux/hooks"
import { useEffect, useState } from "react"
import { getOrganizations } from "src/view-models/Organization"
import { RiLoader2Fill } from "react-icons/ri"
import Select from 'react-select';
import { setStatus } from "src/redux/features/practitioner/practitionerSlice"

const formSchema = z.object({
  identifier: z.string().min(1, {
    message: "L'identifier est obligatoire",
  }),
  phone: z.string().min(1, {
    message: "Le phone est obligatoire",
  }),
  name: z.string().min(1, {
    message: "Le name est obligatoire",
  }),
  family: z.string().min(1, {
    message: "Le family est obligatoire",
  }),
  gender: z.string().min(1, {
    message: "Le gender est obligatoire",
  }),
  birthdate: z.string().min(1, {
    message: "Le birthdate est obligatoire",
  }),
  country: z.string().min(1, {
    message: "Le country est obligatoire",
  }),
  state: z.string().min(1, {
    message: "l address est obligatoire est obligatoire",
  }),
  city: z.string().min(1, {
    message: "Le city est obligatoire",
  }),
  email: z.string()
    .refine((value) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value), {
      message: 'Adresse e-mail invalide',
    }),
  postalCode: z.string().length(5, {
    message: "Le postalCode doit contenir 5 caractères",
  }),
  qualification: z.string().min(1, {
    message: "Le qualification est obligatoire",
  }),
  identifiant_national: z.string().min(1, {
    message: "Le identifiant_national est obligatoire",
  }),
  organizations: z.array(z.string()).min(1, {
    message: "Vous devez sélectionner au moins 1 organisation"
  }),
  password: z.string().min(1, {
    message: "Le password est obligatoire",
  }),
  c_password: z.string().min(1, {
    message: "Le c_password est obligatoire",
  })
})

type NewAssistantFormInputs = {
  identifier: string
  phone: string
  email: string
  country: string
  state: string
  city: string
  postalCode: string
  name: string
  family: string
  gender: string
  birthdate: string
  qualification: string
  identifiant_national: string
  organizations: string[]
  password: string
  c_password: string
};

const NewAssistantForm = () => {
  const dispatch = useAppDispatch()
  const { organizations } = useAppSelector((state) => state.organization)
  const [organizationsOptions, setOrganizationsOptions] = useState<any[]>([])
  const [isLoading, setIsLaoding] = useState(true)
  const toast = useToast()
  const navigation = useNavigate()
  const { register, setValue, handleSubmit, formState: { errors, isSubmitting }, } = useForm<NewAssistantFormInputs>({
    resolver: zodResolver(formSchema),
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const theValues = { ...values, active: 1, roles: ['assistant'] }
    const response: any = await createPractitioner(theValues, toast)
    if (response.status === true) {
      dispatch(setStatus(1))
      navigation("/admin/assistants")
    } else {
      return null
    }
  }

  useEffect(() => {
    const options = organizations?.map((o) => ({
      value: o.id,
      label: o.name
    }))
    setOrganizationsOptions(options)
  }, [organizations])

  useEffect(() => {
    const fetchOrganizations = async () => {
      await getOrganizations(dispatch)
      setIsLaoding(false)
    }
    fetchOrganizations()
  }, [])

  const options = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ];

  const handleChange = (e: any) => {
    setValue('gender', e.value)
  }

  const handleChange2 = (options: any) => {
    const values = options?.map((o: any) => (o.value.toString()))
    setValue('organizations', values)
  }

  return (
    <form className="space-y-4" onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns={{ base: 'repeat(1,1fr)', md: 'repeat(2,1fr)', lg: 'repeat(4,1fr)' }} gap={4} >
        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel>name</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='name'
            placeholder='Nom'
            {...register("name")}
          />
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.family}>
          <FormLabel >Familly</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='family'
            placeholder='Prénom'
            {...register("family")}
          />
          <FormErrorMessage>
            {errors.family && errors.family.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.phone}>
          <FormLabel>Phone</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='tel'
            size='md'
            id='phone'
            placeholder='Téléphone'
            {...register("phone")}
          />
          <FormErrorMessage>
            {errors.phone && errors.phone.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel>Email</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='email'
            size='md'
            id='email'
            placeholder='email'
            {...register("email")}
          />
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl {...register("gender")} isRequired isInvalid={!!errors.gender}>
          <FormLabel>Gender</FormLabel>
          <Select
            placeholder='Sélectionner'
            onChange={handleChange}
            options={options}
          />
          <FormErrorMessage>
            {errors.gender && errors.gender.message}
          </FormErrorMessage>
        </FormControl>
        {isLoading ? (
          <Flex justifyContent='center' alignItems='center' >
            <RiLoader2Fill size={30} className='animate-spin' />
          </Flex>
        ) : (
          <FormControl isRequired isInvalid={!!errors.organizations}>
            <FormLabel>Organizations</FormLabel>
            <Select
              placeholder='Sélectionner'
              isMulti
              onChange={handleChange2}
              options={organizationsOptions}
            />
            <FormErrorMessage>
              {errors.organizations && errors.organizations.message}
            </FormErrorMessage>
          </FormControl>
        )}

        <FormControl isRequired isInvalid={!!errors.birthdate}>
          <FormLabel>birthdate</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='date'
            size='md'
            id='birthdate'
            placeholder='birthdate'
            {...register("birthdate")}
          />
          <FormErrorMessage>
            {errors.birthdate && errors.birthdate.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.country}>
          <FormLabel>Country</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='country'
            placeholder='Pays'
            {...register("country")}
          />
          <FormErrorMessage>
            {errors.country && errors.country.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.city}>
          <FormLabel>City</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='city'
            placeholder='Ville'
            {...register("city")}
          />
          <FormErrorMessage>
            {errors.city && errors.city.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.state}>
          <FormLabel>Adress</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='state'
            placeholder='Adresse'
            {...register("state")}
          />
          <FormErrorMessage>
            {errors.state && errors.state.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.postalCode}>
          <FormLabel>Postal Code</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='number'
            size='md'
            id='postalCode'
            placeholder='Code Postal'
            {...register("postalCode")}
          />
          <FormErrorMessage>
            {errors.postalCode && errors.postalCode.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.qualification}>
          <FormLabel>Qualiication</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='qualification'
            placeholder='qualification'
            {...register("qualification")}
          />
          <FormErrorMessage>
            {errors.qualification && errors.qualification.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.identifier}>
          <FormLabel>Identifier</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='identifier'
            placeholder='Identifiant'
            {...register("identifier")}
          />
          <FormErrorMessage>
            {errors.identifier && errors.identifier.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.identifiant_national}>
          <FormLabel>National id</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='id'
            placeholder='identifiant_national'
            {...register("identifiant_national")}
          />
          <FormErrorMessage>
            {errors.identifiant_national && errors.identifiant_national.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.password}>
          <FormLabel>Assistant Password</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='id'
            placeholder='password'
            {...register("password")}
          />
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.c_password}>
          <FormLabel>Confirm password</FormLabel>
          <Input
            boxShadow='sm'
            disabled={isSubmitting}
            rounded='5px'
            type='text'
            size='md'
            id='id'
            placeholder='confrim password'
            {...register("c_password")}
          />
          <FormErrorMessage>
            {errors.c_password && errors.c_password.message}
          </FormErrorMessage>
        </FormControl>
      </Grid>
      <HStack mt={10}>
        <Button
          type='submit'
          onClick={handleSubmit(onSubmit)}
          isLoading={isSubmitting}
          p='4'
          rounded='20px'
          mx='auto'
          w='40%'
          colorScheme='blue'
          variant='solid'

        >
          Create
        </Button>
      </HStack>
    </form>
  )
}

export default NewAssistantForm