import { Box, Icon, Text, VStack } from "@chakra-ui/react"
import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

type Props = {
    status: string | null,
    color: string,
    title: string,
    resultats: any,
}

const Treatments = ({ color, title, resultats, status }: Props) => {
    const [isShow, setIsShow] = useState(true)

    const showMore = () => {
        setIsShow((prev) => !prev)
    }


    return (
        <Box flex={1} backgroundColor='white' rounded={10} h='fit-content' w='100%'>
            <Box p={3} >
                <Box position='relative' backgroundColor={color === 'red' ? '#EB5757' : color === 'green' ? '#41C174' : 'white'} px={2} color='white' py={2} >
                    <Text fontWeight={600} color={color === 'white' ? 'black' : 'white'}>{title}</Text>
                    {status ? (
                        <Text>{status}</Text>
                    ) : null}
                    <Icon as={isShow ? IoIosArrowUp : IoIosArrowDown} position='absolute' fontSize={25} bottom={-3} right={0} zIndex='50' color='white' backgroundColor='blue.500' rounded='100%' cursor='pointer' onClick={showMore} />
                </Box>
                {isShow ? (
                    <VStack alignItems='start' my={4} >
                        {resultats?.map((resultat: any, index: number) => (
                            <Text key={index} color='black' fontWeight='bold' px={4} >{resultat}</Text>
                        ))}
                    </VStack>
                ) : null}
            </Box>
        </Box>
    )
}

export default Treatments