import { Navigate } from "react-router-dom";
import AdminRoot from "src/components/root/admin/Root";
import Admins from "src/pages/admin/admins/Admins";
import AdminDetails from "src/pages/admin/admins/details/AdminDetails";
import NewAdmin from "src/pages/admin/admins/new/NewAdmin";
import Assistants from "src/pages/admin/assistants/Assistants";
import AssistantDetails from "src/pages/admin/assistants/details/AssistantDetails";
import NewAssistant from "src/pages/admin/assistants/new/NewAssistant";
import Dashboards from "src/pages/admin/dashboards/Dashboards";
import Doctors from "src/pages/admin/doctors/Doctors";

import DoctorDetails from "src/pages/admin/doctors/details/DoctorDetails";
import NewDoctor from "src/pages/admin/doctors/new/NewDoctor";
import Organizations from "src/pages/admin/organization/Organizations";
import OrganizationDetails from "src/pages/admin/organization/details/OrganizationDetails";
import NewOrganization from "src/pages/admin/organization/new/NewOrganization";
import Patients from "src/pages/admin/patients/Patients";
import PatientDetails from "src/pages/admin/patients/details/PatientDetails";
import Questions from "src/pages/admin/qs/Questions";
import QuestionDetails from "src/pages/admin/qs/details/QuestionDetails";
import NewQuestion from "src/pages/admin/qs/new/NewQuestion";
import SeigeFemmes from "src/pages/admin/seige-femme/SeigeFemme";
import SeigeFemmeDetails from "src/pages/admin/seige-femme/details/SeigeFemmeDetails";
import NewSeigeFemme from "src/pages/admin/seige-femme/new/NewSeigeFemme";
import Settings from "src/pages/admin/settings/Settings";


const refresh_token = localStorage.getItem("refresh_token")
const access_token = localStorage.getItem("access_token")

const isSignIn = refresh_token && access_token


const AdminRoutes = {
    
        path:'/admin',
        element:isSignIn ? <AdminRoot /> : <Navigate to='/auth/login' />,
        children:[
          {
            path:'/admin/dashboard',
            element:<Dashboards />
          },
          {
            path:'/admin/admins',
            element:<Admins />
          },
          {
            path:'/admin/admins/new',
            element:<NewAdmin />
          },
          {
            path:'/admin/admins/:id',
            element:<AdminDetails />
          },
          {
            path:'/admin/doctors',
            element:<Doctors />
          },
          {
            path:'/admin/doctors/new',
            element:<NewDoctor />,
          },
          {
            path:'/admin/doctors/:id',
            element:<DoctorDetails />,
          },
          {
            path:'/admin/sage-femmes',
            element:<SeigeFemmes />
          },
          {
            path:'/admin/sage-femmes/new',
            element:<NewSeigeFemme />,
          },
          {
            path:'/admin/sage-femmes/:id',
            element:<SeigeFemmeDetails />,
          },
          {
            path:'/admin/assistants',
            element:<Assistants />
          },
          {
            path:'/admin/assistants/new',
            element:<NewAssistant />,
          },
          {
            path:'/admin/assistants/:id',
            element:<AssistantDetails />,
          },
          {
            path:'/admin/organizations',
            element:<Organizations />,
          },
          {
            path:'/admin/organizations/new',
            element:<NewOrganization />,
          },
          {
            path:'/admin/organizations/:id',
            element:<OrganizationDetails />,
          },
          {
            path:'/admin/patients',
            element:<Patients />
          },
          {
            path:'/admin/patients/:id',
            element:<PatientDetails />,
          },
          {
            path:'/admin/settings',
            element:<Settings />
          },
          {
            path:'/admin/questions',
            element:<Questions />
          },
          {
            path:'/admin/questions/new',
            element:<NewQuestion />
          },
          {
            path:'/admin/questions/:id',
            element:<QuestionDetails />,
          },
          // {
          //   path:'/admin/roles',
          //   element:<Roles />
          // }
        ]
      }

export default AdminRoutes;